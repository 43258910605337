import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type EmptyConversationContentProps = {
  iconSize?: SizeProp;
};

export const EmptyConversationContent: FC<EmptyConversationContentProps> = ({
  iconSize = '8x',
}) => {
  const { t } = useTranslation();

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ flex: 1 }}
      spacing={2}
    >
      <Grid item>
        <FontAwesomeIcon icon="comment-dots" color="#78909C" size={iconSize} />
      </Grid>
      <Grid item>
        <Typography color="primary" variant="body1">
          {t('questionsAndAnswers:prompt')}
        </Typography>
      </Grid>
    </Grid>
  );
};
