import Actionbuttons from 'components/common/actions-buttons';
import React from 'react';
import { Column } from 'types/common';
import { formatMoney, generateRandomString } from 'utils/helpers';
import { AccountingInvolvedParty, AccountingType } from 'v2-types/order';
import { columnsDataSet } from 'views/orders/charges-accounting/components/prototype-new-table-charges/services';

import { CellText } from '../../../components/table-cells';

type RendererParams = {
  row: columnsDataSet;
  column: Column;
  index: number;
  indexRow?: number;
  shouldCollapse?: boolean
};

export type ColumnsType = { [key: string]: Column };

export const generateColumns = (
  t,
  theme,
  handleDeleteRow,
  handleEdit,
  handleCollapse
): ColumnsType => ({
  actions: {
    header: {
      title: '',
      minWidth: '80px',
      width: '80px'
    },
    renderer: ({ row }: RendererParams) => (
      <Actionbuttons
        handleDelete={row?.chargeData?.kind?.includes('manual') ? (e) => {
          e.stopPropagation();
          handleDeleteRow(row.id);
        } : undefined}
        handleEdit={row?.description !== 'Closing Cost Subtotal' ? handleEdit : undefined}
        handleCollapse={row.description === 'Lender Credits' ? () => { handleCollapse(row); } : undefined}
      />
    )
  },
  description: {
    header: {
      title: '',
      align: 'left',
      width: '300px',
      textColor: theme.palette.primary.light
    },
    renderer: ({ row }: RendererParams) => (
      <p style={{ textAlign: 'right', color: theme.palette.primary.light }}>
        {row.description}
      </p>
    )
  },
  amount: {
    header: {
      title: 'Amount Details',
      align: 'left',
      width: '390px',
      textColor: theme.palette.primary.light
    },
    renderer: ({ row }: RendererParams) => (
      <CellText
        position="left"
        backgroundColor="#FAFAFA"
        colorText="#4F5B62"
        data={`${formatMoney(row?.chargeData?.amount || 0)} ${row.tax ? `(Tax: ${formatMoney(row.tax.amount)}, Pending:${formatMoney(row.pending_amount || 0)})` : ''}`}
      />
    )
  },
  paid_borrower: {
    header: {
      title: t('charges:paid-by-borrower'),
      textColor: theme.palette.primary.light
    },
    columns: [
      {
        header: {
          title: t('charges:at-closing'),
          width: '142px',
          textColor: theme.palette.primary.light
        },
        renderer: ({ row, indexRow }: RendererParams) => (
          <CellText
            position="left"
            backgroundColor="#FAFAFA"
            colorText="#4F5B62"
            data={`${indexRow === 1 ? '-' : ''}${formatMoney(row?.buyer_at_closing || 0)}`}
          />
        )
      },
      {
        header: {
          title: t('charges:before-closing'),
          width: '142px',
          textColor: theme.palette.primary.light
        },
        renderer: ({ row, indexRow }: RendererParams) => (
          <CellText
            position="left"
            backgroundColor="#FAFAFA"
            colorText="#4F5B62"
            data={`${indexRow === 1 ? '-' : ''}${formatMoney(row?.buyer_before_closing || 0)}`}
          />
        )
      }
    ]
  },
  paid_seller: {
    header: {
      title: t('charges:paid-by-seller'),
      textColor: theme.palette.primary.light
    },
    columns: [
      {
        header: {
          title: t('charges:at-closing'),
          width: '142px',
          textColor: theme.palette.primary.light
        },
        renderer: ({ row, indexRow }: RendererParams) => (
          <CellText
            position="left"
            backgroundColor="#FAFAFA"
            colorText="#4F5B62"
            data={`${indexRow === 1 ? '-' : ''}${formatMoney(row?.seller_at_closing || 0)}`}
          />
        )
      },
      {
        header: {
          title: t('charges:before-closing'),
          width: '142px',
          textColor: theme.palette.primary.light
        },
        renderer: ({ row, indexRow }: RendererParams) => (
          <CellText
            position="left"
            backgroundColor="#FAFAFA"
            colorText="#4F5B62"
            data={`${indexRow === 1 ? '-' : ''}${formatMoney(row?.seller_before_closing || 0)}`}
          />
        )
      }
    ]
  },
  by_others: {
    header: {
      title: t('charges:to-others'),
      width: '142px',
      textColor: theme.palette.primary.light
    },
    renderer: ({ row, indexRow }: RendererParams) => (
      <CellText
        position="left"
        backgroundColor="#FAFAFA"
        colorText="#4F5B62"
        data={`${indexRow === 1 ? '-' : ''}${formatMoney(row?.by_others || 0)}`}
      />
    )
  },
});

export const formatOThersText = (parties: AccountingInvolvedParty[]) => parties.filter((party) => !party.order_kinds.includes('Buyer')
  && !party.order_kinds.includes('Seller')).map((party) => `${party.name}:${formatMoney(party.amount)}`).join('-');

export const GenerateClosingTotals = (accounting: AccountingType[]) => {
  const defaultCharges = ['A', 'B', 'C', 'E', 'F', 'G', 'H'];
  const filteredCharges = accounting.filter((t) => defaultCharges.some((k) => t?.letter?.includes(k) && t?.number !== null));
  // curr.involved_parties.find((party) => party.order_kinds.includes('Seller') && party.kind.includes('Payer'))?.at_closing_amount || 0;
  const sumAtclosingSeller = filteredCharges.reduce((prev, curr) => {
    const atclosingSeller = curr.involved_parties.filter(party => party.order_kinds.includes('Seller') && party.kind.includes('Payer'))
      .reduce((p, c) => p + (c?.at_closing_amount || 0), 0);
    return prev + atclosingSeller;
  }, 0);
  const sumBeforeClosingSeller = filteredCharges.reduce((prev, curr) => {
    const beforeclosingSeller = curr.involved_parties.filter(party => party.order_kinds.includes('Seller') && party.kind.includes('Payer'))
      .reduce((p, c) => p + (c?.before_closing_amount || 0), 0);
    return prev + beforeclosingSeller;
  }, 0);
  const sumAtclosingBuyer = filteredCharges.reduce((prev, curr) => {
    const atclosingBuyer = curr.involved_parties.filter(party => party.order_kinds.includes('Buyer') && party.kind.includes('Payer'))
      .reduce((p, c) => p + (c?.at_closing_amount || 0), 0);
    return prev + atclosingBuyer;
  }, 0);
  const sumBeforeClosingBuyer = filteredCharges.reduce((prev, curr) => {
    const beforeclosingBuyer = curr.involved_parties.filter(party => party.order_kinds.includes('Buyer') && party.kind.includes('Payer'))
      .reduce((p, c) => p + (c?.before_closing_amount || 0), 0);
    return prev + beforeclosingBuyer;
  }, 0);
  const sumByOthers = filteredCharges.reduce((prev, curr) => {
    const beforeclosingothers = curr.involved_parties.filter((party) => !party.order_kinds.includes('Buyer')
      && !party.order_kinds.includes('Seller')
      && party.kind.includes('Payer'))
      .reduce((previous, current) => previous + current.amount, 0) || 0;
    return prev + beforeclosingothers;
  }, 0);

  return {
    indexRow: 0,
    id: generateRandomString(8),
    description: 'Closing Cost Subtotal',
    payee: '',
    seller_before_closing: sumBeforeClosingSeller,
    seller_at_closing: sumAtclosingSeller,
    buyer_before_closing: sumBeforeClosingBuyer,
    buyer_at_closing: sumAtclosingBuyer,
    by_others: sumByOthers,
    others_description: 'Nothing to show',

  };
};
