import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import { DeleteConfirmationModal } from 'components/delete-confirmation';
import TableComponent from 'components/Table';
import { TFunction } from 'i18next';
import React, { FC, useMemo, useState } from 'react';
import { NewMemberType } from 'types/new-user';

const useStyles = makeStyles((theme: any) => ({
  container: {
    marginTop: theme.spacing(1)
  },
  title: {
    fontWeight: 500,
    textAlign: 'center',
    color: theme.palette.tab.offselected,
  },
  paginationContainer: { marginTop: theme.spacing(2) },
  paginationButton: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  pagintaionIcon: { fontSize: '0.98rem' },
  rowsLetters: { fontWeight: 500 },
  createButton: {
    height: 30,
    textTransform: 'capitalize',
  },
  createButtonIcon: { marginRight: theme.spacing(1) },
}));

type MembersProps = {
  t: TFunction,
  members: NewMemberType[],
  setModalCreateMemberOpen: React.Dispatch<React.SetStateAction<boolean>>,
  deletePartyMember: (memberId: string, onMemberDeleted: () => void) => void,
  isPartyMemberDeleteLoading: boolean
};

const Members: FC<MembersProps> = ({
  t,
  members,
  setModalCreateMemberOpen,
  deletePartyMember,
  isPartyMemberDeleteLoading
}) => {
  const classes = useStyles();

  const offset = 10;
  const [page, setPage] = useState(0);

  const memorizedMembers = useMemo(() => (
    (members || []).slice(page * offset, page * offset + offset)
  ), [members, page]);

  const [memberSelected, setMemberSelected] = useState<NewMemberType | null>(null);

  const handleNavigation = (action: 'prev' | 'next') => {
    if (action === 'prev') {
      setPage((prev) => prev - 1);
      return;
    }

    setPage((prev) => prev + 1);
  };

  return (
    <Grid>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.createButton}
            onClick={() => setModalCreateMemberOpen(true)}
          >
            <FontAwesomeIcon
              icon="plus-square"
              className={classes.createButtonIcon}
            />

            {t('users:add-new')}
          </Button>
        </Grid>
      </Grid>
      {!memorizedMembers.length && (
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.title}>
              {t('users:no-members')}
            </Typography>
          </Grid>
        </Grid>
      )}

      {!!memorizedMembers.length && (
        <Box className={classes.container}>
          <TableComponent
            disabledBorderLeft
            cellAlignment="left"
            columns={{
              actions: {
                header: { title: 'Actions', align: 'center', width: '80px', },
                renderer: ({ row }) => (
                  <Box display="flex" justifyContent="center">
                    <IconButton style={{ padding: 0 }} onClick={() => setMemberSelected(row)}>
                      <FontAwesomeIcon icon="trash" size="xs" />
                    </IconButton>
                  </Box>
                )
              },
              name: {
                header: { title: t('users:name'), align: 'left', width: '500px' },
                renderer: ({ row }) => (
                  <Typography variant="body1" className={classes.rowsLetters}>
                    {row.name}
                  </Typography>
                ),
              },
              kind: {
                header: { title: t('users:kind'), align: 'left', width: "auto" },
                renderer: ({ row }) => (
                  <Typography variant="body1" className={classes.rowsLetters}>
                    {row.kind}
                  </Typography>
                )
              }
            }}
            dataSet={memorizedMembers || []}
          />

          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="flex-end"
            className={classes.paginationContainer}
          >
            <Grid item>
              <Typography>
                {page * offset + offset < members.length ? (
                  `${page * offset + 1} - ${page * offset + offset} of ${members.length}`
                ) : (
                  `${page * offset + 1} - ${members.length} of ${members.length}`
                )}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton
                disabled={page === 0}
                className={classes.paginationButton}
                onClick={() => handleNavigation('prev')}
              >
                <FontAwesomeIcon icon="chevron-left" className={classes.pagintaionIcon} />
              </IconButton>
            </Grid>

            <Grid item>
              <IconButton
                disabled={page * offset + offset >= members.length}
                className={classes.paginationButton}
                onClick={() => handleNavigation('next')}
              >
                <FontAwesomeIcon icon="chevron-right" className={classes.pagintaionIcon} />
              </IconButton>
            </Grid>
          </Grid>

          <DeleteConfirmationModal
            title="Remove Member"
            confirmationMessage="Are you sure you want to remove the member?"
            buttonConfirmText="Remove"
            isOpen={memberSelected !== null}
            isRemoveLoading={isPartyMemberDeleteLoading}
            onClose={() => setMemberSelected(null)}
            onConfirm={() => memberSelected !== null && deletePartyMember(memberSelected!._id!, () => setMemberSelected(null))}
          />
        </Box>
      )}
    </Grid>
  );
};

export default Members;
