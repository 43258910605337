import { yupResolver } from '@hookform/resolvers/yup';
import { createPartyEmail, deletePartyEmail, updatePartyEmail } from 'graphql/parties/mutations';
import { getParty } from 'graphql/parties/queries';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import graphql from 'services/querys';
import useAlert from 'utils/alert';
import {
  CONTACT_TITLE_ADMINISTRATOR,
  CONTACT_TITLE_AGENT
} from 'utils/constants';
import { NewUserType } from 'v2-types/user';

import { getProfile } from '../../../redux/profile-reducer/selectors';
import { useCreateUser } from '../hooks/useCreateUser';
import { useDeleteUser } from '../hooks/useDeleteUser';
import { addLoginEmailIfNotExist } from './services';
import useMutations from './services/useMutations';
import schema from './services/validations';

const AuthorizedAccessToResetPassword = [CONTACT_TITLE_ADMINISTRATOR, CONTACT_TITLE_AGENT];

const useContactEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const showAlert = useAlert();

  const memorizedSchema = useMemo(() => schema(t), [t]);

  const methods = useForm<NewUserType>({
    resolver: yupResolver(memorizedSchema),
    mode: 'onChange',
  });

  const [fetch, setFetch] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [errors, setErrors] = useState<string[]>([]);
  const [modalInvite, setModalInvite] = useState(false);
  const [modalResetPassword, setModalResetPassword] = useState(false);
  const [sendInvitation, setSendInvitation] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState<{ state: boolean; kind: 'delete' | 'unlink'; }>({
    state: false,
    kind: 'delete',
  });
  const [modalCreateMemberOpen, setModalCreateMemberOpen] = useState<boolean>(false);

  const getPartyResponse = useQuery(
    ['getContactEditData', id],
    {
      queryFn: () => getParty({ id }),
      enabled: !!id && fetch,
      refetchOnWindowFocus: false
    },
  );

  const [updateParty, responseUpdateParty] = useMutation(graphql.updateParty);
  const [updateAddress, responseUpdateAddress] = useMutation(graphql.updateAddress);
  const [updatePhone, responseUpdatePhone] = useMutation(graphql.updatePhone);
  const [updateLicense, responseUpdateLicense] = useMutation(graphql.updateLicense);
  const [updatePartyAssociation, responseUpdatePartyAssociation] = useMutation(
    graphql.updatePartyAssociation
  );

  const [createAddress, responseCreateAddress] = useMutation(graphql.createAddress);
  const [createPhone, responseCreatePhone] = useMutation(graphql.createPhone);
  const [createLicense, responseCreateLicense] = useMutation(graphql.createLicense);
  const [createUser, responseCreateUser] = useMutation(graphql.createUser);
  const [createPartyPayment, responsePartyPayment] = useMutation(
    graphql.createPartyPayment
  );

  const [deleteAddress, responseDeleteAddress] = useMutation(graphql.deleteAddress);
  const [deletePhone, responseDeletePhone] = useMutation(graphql.deletePhone);
  const [deleteLicense, responseDeleteLicense] = useMutation(graphql.deleteLicense);
  const [deleteParty, responseDeleteParty] = useMutation(graphql.deleteParty);
  const [deletePartyAssociation, responseDeletePartyAssociation] = useMutation(
    graphql.deletePartyAssociation
  );
  const [deletePartyPayment, responseDeletePartyPayment] = useMutation(
    graphql.deletePartyPayment
  );

  const [resetUrlPassword, responseResetPartyPassword] = useMutation(graphql.resetPartyPassword);
  const [urlResetPassword, setUrlResetPassword] = useState<string>('');
  const [isUrlResetPasswordRequestLoading, setIsUrlResetPasswordRequestLoading] = useState<boolean>(false);
  const [urlResetPasswordError, setUrlResetPasswordError] = useState<string>('');

  const { accesses } = useSelector(getProfile);
  const userHasAccessToResetPassword: boolean = accesses && accesses.some(({ authorization }) => AuthorizedAccessToResetPassword.includes(authorization));

  useMutations(
    id,
    history,
    methods,
    setFetch,
    setErrors,
    sendInvitation,
    setSendInvitation,
    createUser,
    getPartyResponse,
    responseUpdateParty,
    responseUpdateAddress,
    responseUpdatePhone,
    responseUpdateLicense,
    responseUpdatePartyAssociation,
    responseCreateAddress,
    responseCreatePhone,
    responseCreateLicense,
    responseCreateUser,
    responseDeleteAddress,
    responseDeletePhone,
    responseDeleteLicense,
    responseDeleteParty,
    responseDeletePartyAssociation,
    responsePartyPayment,
    responseDeletePartyPayment,
    responseResetPartyPassword,
    setUrlResetPassword,
    setIsUrlResetPasswordRequestLoading,
    setUrlResetPasswordError
  );

  const [createUserMutation, responseCreateUserMutation] = useCreateUser({
    onSuccess(data) {
      responseCreateUserMutation.reset();
      methods.setValue('user_id', data.createUser._id);
      methods.setValue('username', data.createUser.username);
      const emails = addLoginEmailIfNotExist(data.createUser.emails, data.createUser.username, data.createUser._id);
      methods.setValue('emails', emails);
      showAlert(t('users:user-linked-success'), 'success');
    },
    onError(error) {
      responseCreateUserMutation.reset();
      const errorList = ['Error create email', error.message];
      setErrors((prev) => [...prev, ...errorList]);
    },
  });

  const [deleteUser, responseDeleteUser] = useDeleteUser({
    onSuccess: (data) => {
      responseDeleteUser.reset();
      methods.setValue('user_id', data.deleteUser.user_id);
      methods.setValue('username', data.deleteUser.username);
      methods.setValue('emails', data.deleteUser.emails);
      showAlert(t('users:user-unlinked-success'), 'success');
    },
    onError: () => {
      responseDeleteUser.reset();
      showAlert(t('users:error-unlinking-user'), 'error');
    }
  });

  const [createEmail, responseCreateEmail] = useMutation(createPartyEmail, {
    onSuccess: (response) => {
      if (response.createEmail) {
        responseCreateEmail.reset();

        const { emails, username, _id } = response.createEmail;
        const emailsData = addLoginEmailIfNotExist(emails, username, _id);
        methods.setValue('emails', emailsData);

        if (sendInvitation) {
          setSendInvitation(false);
          createUser({ id });
        }
      }
    },
    onError: () => {
      responseCreateEmail.reset();
      let { createErrors = ['Error create email'] } = (
        responseCreateEmail.error as any
      ).response;
      createErrors = errors.map((error: any) => error.message);
      setErrors((prev) => [...prev, createErrors]);
    }
  });

  const [updateEmail, responseUpdateEmail] = useMutation(updatePartyEmail, {
    onSuccess: (response) => {
      if (response.updateEmail) {
        responseUpdateEmail.reset();

        const { emails, username, _id } = response.updateEmail;
        const emailsData = addLoginEmailIfNotExist(emails, username, _id);
        methods.setValue('emails', emailsData);

        if (sendInvitation) {
          setSendInvitation(false);
          createUser({ id });
        }
      }
    },
    onError: (error) => {
      responseUpdateEmail.reset();
      let { updateErrors = ['Error update email'] } = (
        error as any
      ).response;
      updateErrors = errors.map((updateError: any) => updateError.message);
      setErrors((prev) => [...prev, updateErrors]);
    }
  });

  const [deleteEmail, responseDeleteEmail] = useMutation(deletePartyEmail, {
    onSuccess: (response) => {
      if (response.deleteEmail) {
        responseDeleteEmail.reset();
        const { emails, username, _id } = response.deleteEmail;
        const emailsData = addLoginEmailIfNotExist(emails, username, _id);
        methods.setValue('emails', emailsData);
      }
    },
    onError: (error) => {
      responseDeleteEmail.reset();
      let { deleteErrors = ['Error delete email'] } = (
        error as any
      ).response;
      deleteErrors = errors.map((deleteError: any) => deleteError.message);
      setErrors((prev) => [...prev, deleteErrors]);
    }
  });

  const deletePartyMember = (memberId: string, onMemberDeleted: () => void) => {
    const partyId = getPartyResponse.data?.getParty._id;
    if (partyId) {
      deletePartyAssociation({ partyId, id: memberId }, {
        onSuccess: () => {
          setFetch(true);
          onMemberDeleted();
        }
      });
    }
  };

  return {
    t,
    methods,
    history,
    errors,
    loading: getPartyResponse.isLoading,
    currentTab,
    setCurrentTab,
    modalInvite,
    setModalInvite,
    setSendInvitation,
    updateParty,
    updateAddress,
    updateEmail,
    updatePhone,
    updateLicense,
    updatePartyAssociation,
    createAddress,
    createEmail,
    createPhone,
    createLicense,
    deleteAddress,
    deleteEmail,
    deletePhone,
    deleteLicense,
    deleteUser,
    deleteParty,
    deletePartyAssociation,
    confirmModalOpen,
    setConfirmModalOpen,
    createPartyPayment,
    deletePartyPayment,
    modalResetPassword,
    setModalResetPassword,
    resetUrlPassword,
    urlResetPassword,
    urlResetPasswordError,
    setUrlResetPassword,
    isUrlResetPasswordRequestLoading,
    setUrlResetPasswordError,
    userHasAccessToResetPassword,
    createUserMutation,
    setErrors,
    modalCreateMemberOpen,
    setModalCreateMemberOpen,
    getPartyResponse,
    isDeletePartyAssociationLoading: responseDeletePartyAssociation.isLoading,
    deletePartyMember,
  };
};

export default useContactEdit;
