import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { updateType } from '../services';

const useStyles = makeStyles((theme: any) => ({
  headerContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottomColor: theme.accent.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  headerTitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
}));

type DatesAndconfigHead = {
  latestUpdate?: updateType

}
const Header: FC<DatesAndconfigHead> = ({ latestUpdate }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.headerContainer}
    >
      <Grid item>
        <Typography variant="h5" className={classes.headerTitle}>
          {t('datesAndConfig:dates-and-configuration')}
        </Typography>
      </Grid>
      {latestUpdate
        && (
          <Grid item>
            <Alert severity={latestUpdate?.type}>
              {latestUpdate?.message}
            </Alert>
          </Grid>
        )}
    </Grid>
  );
};

export default Header;
