import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';

export type GQLCreateOrderAIResponse = {
	helpOrderByAI: {
	  _id: string;
	}
  };

export type CreateOrderAIParams = {
	transactionType: string | null,
	type: string | null,
	workflow: string | null,
	loanNumber: string | null,
	agencyId: string | null,
	involvedParties:{_id:string, kinds: string[]}[],
	  property: {
		kind: string,
		country: string,
		settlement?: string,
		locality?: string,
		state: string,
		postal_code: string,
		street_address: string,
		references?:string
	  },
	  amounts:
		{
			code: string,
			kind: string,
			amount_override: number
		}[],
	  dates: {
		disbursement_date: string | null | Date,
		acceptance_date: string| null |Date,
		closing_date: string| null |Date
	  }
  }

export const createOrderAI = async (payload: CreateOrderAIParams) => {
  const mutation = gql`
	mutation HelpOrderByAI(
	$transactionType: String!, 
	$type: String!, 
	$workflow: String!, 
	$loanNumber: String, 
	$dates: DatesAIContent, 
	$amounts: [AmountAIContent], 
	$property: AddressAIContent, 
	$involvedParties: [InvolvedPartiesAIContent], 
	$agencyId: ID) {
  		helpOrderByAI(
		transaction_type: $transactionType, 
		type: $type, 
		workflow: $workflow, 
		loan_number: $loanNumber, 
		dates: $dates, 
		amounts: $amounts, 
		property: $property, 
		involved_parties: $involvedParties, 
		agency_id: $agencyId) {
    		_id
  		}
	}
	`;

  const response = await graphQLClient()
	  .request<GQLCreateOrderAIResponse>(mutation, payload);

  return response;
};
