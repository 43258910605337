import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { BaseButton } from 'components/BaseButton';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useMortgagesContext } from '../services/context';
import PayoffButton from './header-payoff-button';
import AddMortgageModal from './mortgage-detail/components/add-mortgage-modal/add-mortgage-modal';

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  buttonsContainer: { marginBottom: theme.spacing(2) },
  payoffButtonsContainer: {
    maxWidth: 700,
    overflow: 'auto',
  },
  payoffAddButton: {
    color: theme.palette.primary.main,
    borderRadius: theme.spacing(0.5),
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    marginLeft: theme.spacing(1),
    height: '100%',
    textTransform: 'none',
    // '&:hover': { backgroundColor: theme.palette.input.border },
  },
  saveButtonContainer: { marginLeft: theme.spacing(4) },
  headerContainer: {
    borderBottomColor: theme.accent.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const HeaderPayoffs: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const handleViewChange = (id: string) => {
    history.push(`${url.endsWith('/') ? url : url.concat('/')}mortgage/${id}`);
  };
  const idMortgageRoute =
    window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
  const { mortgages, loadingMortgages } = useMortgagesContext();
  const [addMortgage, setOpenAddMortgage] = useState(false);
  return (
    <Grid item xs={12} className={classes.headerContainer}>
      <Grid container direction="row">
        <Grid item xs={4}>
          <Typography variant="h5" className={classes.title}>
            {t('payoffs:payoffs')}
          </Typography>
        </Grid>

        <Grid item xs={8} className={classes.buttonsContainer}>
          <Grid container direction="row-reverse">
            <Box display="flex">
              <Box display="flex" className={classes.payoffButtonsContainer}>
                {mortgages.map((mortgage, index) => (
                  <PayoffButton
                    key={mortgage._id}
                    selected={mortgage._id === idMortgageRoute}
                    index={index + 1}
                    handleSelectPayoff={() => {
                      handleViewChange(mortgage._id);
                    }}
                    t={t}
                  />
                ))}
              </Box>
              <Box className={classes.saveButtonContainer}>
                <BaseButton
                  buttonVariant="contained"
                  buttonColor="secondary"
                  text={t('payoffs:add-payoff')}
                  icon="plus"
                  disableElevation
                  disabled={loadingMortgages}
                  isLoading={loadingMortgages}
                  onClick={() => {
                    setOpenAddMortgage(true);
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {addMortgage && (
        <AddMortgageModal
          open={addMortgage}
          onClose={() => {
            setOpenAddMortgage(false);
          }}
        />
      )}
    </Grid>
  );
};

export default HeaderPayoffs;
/*

*/
