import { GeneralInformationValuesType } from 'components/users/types';
import React, { FC } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { CURRENT_PATH, DEFAULT_ROUTE } from 'utils/constants';
import { NewUserType } from 'v2-types/user';

import useContactEdit from './contact-edit-hook';
import ContactEditScene from './contact-edit-scene';
import {
  createAddress as fnCreateAddress,
  createEmail as fnCreateEmail,
  createLicense as fnCreateLicense,
  createPhone as fnCreatePhone,
  updateAddress as fnUpdateAddress,
  updateAssociation as fnUpdateAssociation,
  updateBasicInformation,
  updateEmail as fnUpdateEmail,
  updateLicense as fnUpdateLicense,
  updatePhone as fnUpdatePhone
} from './services';

const UserEdit: FC = () => {
  const {
    t,
    methods,
    history,
    errors,
    loading,
    currentTab,
    setCurrentTab,
    modalInvite,
    setModalInvite,
    updateParty,
    updateAddress,
    updateEmail,
    updatePhone,
    updateLicense,
    updatePartyAssociation,
    createAddress,
    createEmail,
    createPhone,
    createLicense,
    deleteAddress,
    deleteEmail,
    deletePhone,
    deleteLicense,
    deleteUser,
    deleteParty,
    deletePartyAssociation,
    confirmModalOpen,
    setConfirmModalOpen,
    createPartyPayment,
    deletePartyPayment,
    modalResetPassword,
    setModalResetPassword,
    resetUrlPassword,
    urlResetPassword,
    urlResetPasswordError,
    setUrlResetPassword,
    isUrlResetPasswordRequestLoading,
    setUrlResetPasswordError,
    userHasAccessToResetPassword,
    createUserMutation,
    setErrors,
    modalCreateMemberOpen,
    setModalCreateMemberOpen,
    getPartyResponse,
    deletePartyMember,
    isDeletePartyAssociationLoading
  } = useContactEdit();

  const helpers = (
    getValues: UseFormGetValues<NewUserType>
  ): GeneralInformationValuesType => ({
    first_name: {
      disabled: getValues('kind') === 'Title Company',
      onBlur: (event) => {
        updateParty(updateBasicInformation(getValues()));
        event();
      },
    },
    middle_name: {
      onBlur: (event) => {
        updateParty(updateBasicInformation(getValues()));
        event();
      },
    },
    last_name: {
      onBlur: (event) => {
        updateParty(updateBasicInformation(getValues()));
        event();
      },
    },
    ssn: {
      onBlur: (event) => {
        updateParty(updateBasicInformation(getValues()));
        event();
      },
    },
    birth: {
      onBlur: (event) => {
        updateParty(updateBasicInformation(getValues()));
        event();
      },
    },
    gender: {
      onBlur: (event) => {
        updateParty(updateBasicInformation(getValues()));
        event();
      },
    },
    addresses: {
      add: async (_data, onClose) => {
        await createAddress(fnCreateAddress(getValues('_id'), _data));
        onClose!();
      },
      remove: (id) => deleteAddress({ id, partyId: getValues('_id') }),
      addresses: (getValues('addresses') || []).map((address) => ({
        street_address: {
          onBlur: (event) => {
            updateAddress(fnUpdateAddress(getValues('_id'), address));
            event();
          },
        },
        state: {
          onBlur: (event) => {
            updateAddress(fnUpdateAddress(getValues('_id'), address));
            event();
          },
        },
        postal_code: {
          onBlur: (event) => {
            updateAddress(fnUpdateAddress(getValues('_id'), address));
            event();
          },
        },
        references: {
          onBlur: (event) => {
            updateAddress(fnUpdateAddress(getValues('_id'), address));
            event();
          },
        },
        locality: {
          onBlur: (event) => {
            updateAddress(fnUpdateAddress(getValues('_id'), address));
            event();
          },
        },
        settlement: {
          onBlur: (event) => {
            updateAddress(fnUpdateAddress(getValues('_id'), address));
            event();
          },
        },
        kind: {
          onBlur: (event) => {
            updateAddress(fnUpdateAddress(getValues('_id'), address));
            event();
          },
        },
      })),
    },
    emails: {
      add: async (_data, onClose) => {
        await createEmail(fnCreateEmail(getValues('_id'), _data));
        onClose!();
      },
      remove: (id) => deleteEmail({ id, partyId: getValues('_id') }),
      emails: (getValues('emails') || []).map((email) => ({
        address: {
          disabled: (() => {
            if (email.kind === 'Login' && getValues('user_id')) {
              return true;
            }

            return false;
          })(),
          onBlur: (event) => {
            updateEmail(fnUpdateEmail(getValues('_id'), email));
            event();
          },
        },
        kind: {
          disabled: (() => {
            if (email.kind === 'Login' && getValues('user_id')) {
              return true;
            }

            return false;
          })(),
          onBlur: (event) => {
            updateEmail(fnUpdateEmail(getValues('_id'), email));
            event();
          },
        },
      })),
    },
    phones: {
      add: async (_data, onClose) => {
        await createPhone(fnCreatePhone(getValues('_id'), _data));
        onClose!();
      },
      remove: (id) => deletePhone({ id, partyId: getValues('_id') }),
      phones: (getValues('phones') || []).map((phone) => ({
        number: {
          onBlur: (event) => {
            updatePhone(fnUpdatePhone(getValues('_id'), phone));
            event();
          },
        },
        kind: {
          onBlur: (event) => {
            updatePhone(fnUpdatePhone(getValues('_id'), phone));
            event();
          },
        },
      })),
    },
    licenses: {
      add: async (_data, onClose) => {
        await createLicense(fnCreateLicense(getValues('_id'), _data));
        onClose!();
      },
      remove: (id) => deleteLicense({ id, partyId: getValues('_id') }),
      licenses: (getValues('licenses') || []).map((license) => ({
        number: {
          onBlur: (event) => {
            updateLicense(fnUpdateLicense(getValues('_id'), license));
            event();
          },
        },
        state: {
          onBlur: (event) => {
            updateLicense(fnUpdateLicense(getValues('_id'), license));
            event();
          },
        },
      })),
    },
    parties: {
      remove: (partyId) => deletePartyAssociation({ id: getValues('_id'), partyId }),
      parties: (getValues('parties') || []).map((party) => ({
        name: { disabled: true },
        kind: {
          disabled: false,
          onBlur: (event) => {
            updatePartyAssociation(
              fnUpdateAssociation(getValues('_id'), party)
            );
            event();
          },
        },
      })),
    },
    payments: {
      add: async (_data, onClose) => {
        const kind = Object.keys(_data)[0];

        await createPartyPayment({
          partyId: getValues('_id'),
          data: _data[kind]!,
          kind,
        });

        onClose!();
      },
      remove: (paymentId) => {
        deletePartyPayment({ id: getValues('_id'), paymentId });
      },
      payments: (getValues('payments') || []),
    },
  });

  const handleUnlink = (id: string) => deleteUser({ id });

  const handleDelete = (id: string) => deleteParty({ id });

  const handleOpenInviteUser = (state: boolean) => setModalInvite(state);

  const handleCreateLoginEmail = (email: string, onClose: () => void) => {
    createUserMutation({
      id: methods.getValues('_id'),
      username: email
    });

    onClose();
  };

  const handleNavigateBack = () => history.goBack();

  const handleOnChange = (_: React.ChangeEvent<{}>, newValue: number) => setCurrentTab(newValue);

  const openWorkspace = (orderId: string) => {
    localStorage.setItem(CURRENT_PATH, JSON.stringify({ view: DEFAULT_ROUTE, param: '' }));
    history.push(`/orders/details/${orderId}/${DEFAULT_ROUTE}`);
  };

  const handleOpenConfirmModal = (state: boolean, kind: 'delete' | 'unlink') => {
    setConfirmModalOpen({ state, kind });
  };

  const handleCloseModalResetPassword = () => {
    setUrlResetPassword('');
    setUrlResetPasswordError('');
    setModalResetPassword(false);
  };

  const handleResetPartyPassword = (email: string) => {
    resetUrlPassword({ email });
  };

  return (
    <ContactEditScene
      t={t}
      methods={methods}
      helpers={helpers}
      loading={loading}
      errors={errors}
      currentTab={currentTab}
      modalInvite={modalInvite}
      confirmModalOpen={confirmModalOpen}
      handleUnlink={handleUnlink}
      handleDelete={handleDelete}
      handleCreateLoginEmail={handleCreateLoginEmail}
      handleOpenInviteUser={handleOpenInviteUser}
      handleNavigateBack={handleNavigateBack}
      handleOnChange={handleOnChange}
      handleOpenConfirmModal={handleOpenConfirmModal}
      openWorkspace={openWorkspace}
      modalResetPassword={modalResetPassword}
      handleOpenModalResetPassword={setModalResetPassword}
      handleResetPartyPassword={handleResetPartyPassword}
      urlResetPassword={urlResetPassword}
      urlResetPasswordError={urlResetPasswordError}
      isUrlResetPasswordRequestLoading={isUrlResetPasswordRequestLoading}
      handleCloseModalResetPassword={handleCloseModalResetPassword}
      userHasAccessToResetPassword={userHasAccessToResetPassword}
      setErrors={setErrors}
      modalCreateMemberOpen={modalCreateMemberOpen}
      setModalCreateMemberOpen={setModalCreateMemberOpen}
      getPartyResponse={getPartyResponse}
      deletePartyMember={deletePartyMember}
      isDeletePartyAssociationLoading={isDeletePartyAssociationLoading}
    />
  );
};

export default UserEdit;
