import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import { DocumentsType } from 'v2-types/order';

import { SelectedFilesType } from '../../types';
import FileRow from './file-row';
import { useTranslation } from 'react-i18next';

type TableDocumentsProps = {
  documents: DocumentsType[];
  handleToggleModal: (archive_id: string, location: string, data?: DocumentsType) => void;
  handleRemoveData: (payload: any) => void;
  handleRemoveFile: (fileId: string) => void;
  handleCheckboxChange: (file: SelectedFilesType) => void;
  selectedFiles: SelectedFilesType[];
  searchWordClass: string;
  handleToggleSelectAllFiles: () => void;
  handleRemoveArchive: ({
    documentId,
    archiveId,
    fileId,
  }: {
    documentId: string;
    archiveId: string;
    fileId: string;
  }) => void;
};

const TableDocuments: FC<TableDocumentsProps> = ({
  documents,
  handleToggleModal,
  handleRemoveData,
  handleRemoveFile,
  handleCheckboxChange,
  selectedFiles,
  searchWordClass,
  handleToggleSelectAllFiles,
  handleRemoveArchive,
}) => {
  const { t } = useTranslation();
  return (
    <Box maxHeight={550} overflow="scroll">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ color: '#000' }}>
              {t('documents:description')}
              {documents.length > 0 && (
                <>
                  <br />
                  <Checkbox onChange={handleToggleSelectAllFiles} />
                  <Typography variant="caption">{t('documents:select-doc')}</Typography>
                </>
              )}
            </TableCell>
            <TableCell style={{ color: '#000' }}>{t('documents:type')}</TableCell>
            <TableCell style={{ color: '#000' }}>{t('documents:actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((document) => (
            <FileRow
              key={document._id}
              document={document}
              handleToggleModal={handleToggleModal}
              handleRemoveData={handleRemoveData}
              handleRemoveFile={handleRemoveFile}
              handleCheckboxChange={handleCheckboxChange}
              selectedFiles={selectedFiles}
              searchWordClass={searchWordClass}
              handleRemoveArchive={handleRemoveArchive}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default TableDocuments;
