import { getLedgerListings } from 'graphql/ledger/queries';
import { useGetOrderParties } from 'hooks/useGetOrderParties';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { listingItemtype, PartyAccounting } from 'types/ledger';
import useAlert from 'utils/alert';
import {
  AccountingFilter,
  AccountingInvolvedParty,
  AccountingType,
} from 'v2-types/order';

import {
  addpartyLedgerAssociation,
  updateLedgerAssociation,
} from './services/mutations';
import { AddAndUpdatePartyLedgerForm } from './types';
import { isArrayOfStrings } from './utils';
import { getInitialValues } from './utils/initialValues';

type UseAddAndUpdatePartyLedgerEntryParams = {
  accountingEntryId: string;
  prevAccountingPartyEntry?: AccountingInvolvedParty;
  handleClose: () => void;
  handleSuccess: (accounting: AccountingType[]) => void;
  kindAllowedAssociationList?: string | string[];
  accountingFilter?: AccountingFilter;
  partyKindFilter?: string[]
};

export const useAddAndUpdatePartyLedgerEntry = ({
  accountingEntryId,
  prevAccountingPartyEntry,
  handleClose,
  handleSuccess,
  kindAllowedAssociationList,
  accountingFilter,
  partyKindFilter
}: UseAddAndUpdatePartyLedgerEntryParams) => {
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  const [allowedAssociations, setAllowedAssociations] = useState<
    listingItemtype[]
  >([]);
  const [currentPartySelected, setCurrentPartySelected] = useState<PartyAccounting | null>(null);
  const [createErrors, setCreateErrors] = useState<string[]>([]);
  const showAlert = useAlert();

  const methods = useForm<AddAndUpdatePartyLedgerForm>({ defaultValues: getInitialValues(prevAccountingPartyEntry), });

  const { data: getOrderPartiesData } = useGetOrderParties({
    orderId,
    kindsFilter: partyKindFilter,
    queryConfig: {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (!prevAccountingPartyEntry && data.getOrder.parties.length > 0) {
          methods.setValue('party_id', data.getOrder.parties[0]._id);
        }
      }
    }
  });

  const parties = (getOrderPartiesData?.getOrder.parties || []) as PartyAccounting[];

  const getLedgerAllowedAssociations = useQuery(
    ['allowed-ledger-Associations', 'allowed_ledger_associations'],
    () => getLedgerListings('allowed_ledger_associations'),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          const unfilterAssociationList = data.getListing.entries || [];
          let allowedAssociationsKind = unfilterAssociationList;

          if (kindAllowedAssociationList) {
            if (isArrayOfStrings(kindAllowedAssociationList)) {
              allowedAssociationsKind = unfilterAssociationList.filter(
                (association) => kindAllowedAssociationList.some((k) => association.code.toLowerCase().includes(k.toLowerCase()))
              );
            } else {
              allowedAssociationsKind = unfilterAssociationList.filter(
                (association) => association.code.toLowerCase().includes(kindAllowedAssociationList.toLowerCase())
              );
            }
          }

          if (!prevAccountingPartyEntry) {
            methods.setValue('kind', allowedAssociationsKind[0].code);
          }
          setAllowedAssociations(allowedAssociationsKind);
        }
      },
    }
  );

  const [createLedgerAssociation, createLedgerAssociationResponse] = useMutation(addpartyLedgerAssociation, {
    onSuccess: (data) => {
      if (data) {
        showAlert(t('documents:success-updating'), 'success');
        handleSuccess(data.createOrderLedgerAssociation.accounting || []);
        createLedgerAssociationResponse.reset();
        methods.reset();
        setCreateErrors([]);
        handleClose();
      }
    },
    onError: (e: any) => {
      const { errors = ['Error Adding Association'] } = e?.response || {};
      const errorsMapped = errors.map((error: any) => error.message);
      setCreateErrors([...errorsMapped]);
    },
  });

  const [editLedgerAssociation, editLedgerAssociationResponse] = useMutation(
    updateLedgerAssociation,
    {
      onSuccess: (data) => {
        if (data) {
          handleSuccess(data.updateOrderLedgerAssociation.accounting || []);
          editLedgerAssociationResponse.reset();
          methods.reset();
          showAlert(t('documents:success-updating'), 'success');
          setCreateErrors([]);
          handleClose();
        }
      },
      onError: (e: any) => {
        const { errors = ['Error Adding Association'] } = e?.response || {};
        const errorsMapped = errors.map((error: any) => error.message);
        setCreateErrors([...errorsMapped]);
      },
    }
  );

  const handleAssociationCreateLedger = methods.handleSubmit((data) => {
    if (prevAccountingPartyEntry) {
      editLedgerAssociation({
        id: orderId,
        accountingId: accountingEntryId,
        data: {
          at_closing_percent:
            parseFloat(data.at_closing_percent.toString()) / 100,
          payment_id: data.payment_id,
          percent: parseFloat(data.percent.toString()) / 100,
        },
        kind: data.kind,
        partyId: prevAccountingPartyEntry._id,
        filters: accountingFilter
          ? { accounting: accountingFilter }
          : undefined,
      });
      return;
    }
    createLedgerAssociation({
      id: orderId,
      accountingId: accountingEntryId,
      data: {
        at_closing_percent:
          parseFloat(data.at_closing_percent.toString()) / 100,
        payment_id: data.payment_id,
        percent: parseFloat(data.percent.toString()) / 100,
      },
      kind: data.kind,
      partyId: data.party_id,
      filters: accountingFilter ? { accounting: accountingFilter } : undefined,
    });
  });

  return {
    t,
    methods,
    handleAssociationCreateLedger,
    allowedAssociations,
    loadingAssociations: getLedgerAllowedAssociations.isLoading,
    setCurrentPartySelected,
    currentPartySelected,
    createErrors,
    loadingEditAssociation: editLedgerAssociationResponse.isLoading,
    loadingAddAssociation: createLedgerAssociationResponse.isLoading,
    parties
  };
};
