import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  capitalize,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CustomPartyOption from 'components/add-contact-modal/components/custom-party-option';
import TextField from 'components/text-field';
import ContactCreation from 'components/users/form-contact-creation/contact-creation';
import React, { FC, useEffect, useState } from 'react';
import { Control, Controller, UseFieldArrayRemove, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NewUserType } from 'v2-types/user';

import { formInfoOrder } from '../services';
import useSearchParty from 'hooks/useSearchParty';
import { ContainedButton } from 'components/ContainedButton';

function capitalizePhrase(phrase) {
  return phrase.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
const useStyles = makeStyles((theme: any) => ({
  titleRole: {
    fontWeight: 500,
    color: theme.palette.tab.offselected,
  },
  containerSelectedUser: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(1)
  }
}));

type PartiesItemSearchPros = {
  partyText: string,
  role: string,
  control: Control<formInfoOrder, object>
  index: number,
  setValue: UseFormSetValue<formInfoOrder>
  remove: UseFieldArrayRemove,
  getValues: UseFormGetValues<formInfoOrder>
}

const PartiesItemSearch: FC<PartiesItemSearchPros> = ({
  partyText,
  role,
  control,
  index,
  setValue,
  getValues,
  remove
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string | undefined>(partyText);
  const [contactCreation, setContatCreation] = useState(false);
  const [newContactKind, setNewContactKind] = useState<string>("Person");
  const [contactSelected, setContactSelected] = useState<string | null>(null);
  const { parties } = useSearchParty(searchText);

  const capitalizedRole = capitalizePhrase(role.replace('_', ' ').replace('_', ' '));
  const isCompany = getValues(`${role}_company` as any) as boolean || false;

  return (
    <Grid
      container
      direction="column"
      style={{
        border: '1px solid lightgray',
        borderRadius: 8,
        padding: 16
      }}
      spacing={1}
    >
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Typography variant="body2" className={classes.titleRole}>{`Role: ${capitalizedRole}`}</Typography>
            <Typography variant="body2" style={{ fontWeight: 500, color: 'black' }}>{`Contact found in the document: ${partyText}`}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              style={{ padding: 0 }}
              onClick={() => {
                remove(index);
              }}
            >
              <FontAwesomeIcon icon="trash-alt" size="sm" style={{ fontSize: 14, color: 'black' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {contactCreation ? (
          <Box display="flex" flex="1" flexDirection="column" className={classes.containerSelectedUser}>
            <ContactCreation
              selectedKind={newContactKind}
              kindList={['Person', 'Company']}
              onSuccess={({ _id, name }) => {
                setValue(`parties.${index}.partySelected`, _id);
                setContactSelected(name);
                setContatCreation(false);
              }}
              handleCancel={() => {
                setContatCreation(false);
              }}
              searchText={partyText}
            />
          </Box>
        ) : (
          <Controller
            control={control}
            name={`parties.${index}.partySelected`}
            rules={{ required: true }}
            render={({ field: { ...field } }) => (
              field.value === ''
                ? (
                  <Box>
                    <Autocomplete
                      fullWidth
                      options={parties}
                      getOptionLabel={(party) => party.name || ''}
                      onChange={(_, value) => {
                        field.onChange(value?._id);
                        setContactSelected(value?.name || '');
                      }}
                      onInputChange={(_, value, reason) => {
                        if (reason === 'input' || reason === 'clear') {
                          setSearchText(value);
                        }
                      }}
                      filterOptions={(options) => options}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputRef={params.InputProps.ref}
                          InputProps={params.inputProps}
                          label={t('parties:contact')}
                        />
                      )}
                      renderOption={(props) => (
                        <CustomPartyOption
                          contact={props}
                          handleContactCreation={(_) => {
                            setContatCreation(true);
                            const getKind = isCompany ? 'Company' : 'Person';
                            setNewContactKind(getKind);
                          }}
                          singleCreateOption
                          t={t}
                        />
                      )}
                    />
                    <Box style={{ marginTop: 8, marginBottom: 8 }}>
                      <Typography variant="body2" style={{ fontWeight: 500 }}>
                        {`${parties.length > 0 ? `We found ${parties.length} matches in contacts, select one of those in the field or ` : ''}looking for any contact 
                      in the system writing there or you can create a new contact in the button below`}
                      </Typography>
                    </Box>
                    <Box display="flex" flex="1" style={{ padding: 3 }}>
                      <ContainedButton
                        size="large"
                        text="Create Contact"
                        onClick={(e) => {
                          e.stopPropagation();
                          setContatCreation(true);
                          const getKind = isCompany ? 'Company' : 'Person';
                          setNewContactKind(getKind);
                        }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="row" className={classes.containerSelectedUser}>
                      <Box>
                        <IconButton
                          style={{ padding: 0, marginRight: 16 }}
                          onClick={() => {
                            field.onChange('');
                          }}
                        >
                          <FontAwesomeIcon icon="times" size="xs" />
                        </IconButton>
                      </Box>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body2" style={{ fontWeight: 500, color: 'black' }}>{`${contactSelected}`}</Typography>
                      </Box>
                    </Box>
                  </Box>
                )
            )}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PartiesItemSearch;
