import { Box, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { general } from '../styles/general';

const useStyles = makeStyles((theme) => general(theme));
const ChargesContainer: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.chargesContainer}>
      {children}
    </Box>
  );
};

export default ChargesContainer;