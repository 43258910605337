import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useStyles } from '../../styles';
import { LoanFormType } from '../../types';
import { AmountKindForm } from '../amount-kind-form';
import { MoneyField } from '../moneyField';
import { useTranslation } from 'react-i18next';

type Props = {
  isDataLoading: boolean;
  loanForm: UseFormReturn<LoanFormType, object>;
};

export const LoanAmountAndAmountKind: FC<Props> = ({ isDataLoading, loanForm }) => {
  const classes = useStyles();
  const { control } = loanForm;
  const { t } = useTranslation();

  return (
    <Box style={{ display: 'flex', gap: '20px', alignItems: 'flex-end' }}>
      <Box style={{ flex: 1 }}>
        <MoneyField
          control={control}
          name="loanAmountEntry.amount"
          label={t('loan:loan-amount')}
          disabled={isDataLoading}
        />
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Typography variant="h6" className={classes.subTitle}>
          {t('loan:amount-kind')}
        </Typography>
        <AmountKindForm loanForm={loanForm} isLoanLoading={isDataLoading} />
      </Box>
    </Box>
  );
};
