import React, { FC } from 'react';
import { AccountingCharge } from '../types';
import { Control, useWatch } from 'react-hook-form';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import NumberField from 'components/number-field';
import TextField from 'components/text-field';

const useStyles = makeStyles((theme: any) => ({
  tableHeadRowSecond: {
    position: 'sticky',
    background: 'white',
    top: 56,
  },
  tableHeaderPayee: {
    minWidth: '300px',
  },
  tableHeaderNumber: {
    minWidth: '90px',
  },
  numberField: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.dark,
      borderRadius: '5px',
      fontSize: '14px',

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'unset',
      },

      '& .MuiOutlinedInput-input ': {
        textAlign: 'center',
      },
    },
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.default,
      borderRadius: '5px',
      color: theme.palette.primary.dark,
      fontSize: '14px',

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'unset',
      },
    },
  },
}));

const calcResults = (localChares: AccountingCharge[]) => localChares.reduce((prev, curr) => ({
  buyerAtclosing: prev.buyerAtclosing + curr.buyer_at,
  buyerBefore: prev.buyerBefore + curr.buyer_before,
  sellerAtclosing: prev.sellerAtclosing + curr.seller_at,
  sellerBefore: prev.sellerBefore + curr.seller_before,
  otherTotal: prev.otherTotal + curr.by_others,
}), {
  buyerAtclosing: 0,
  buyerBefore: 0,
  sellerAtclosing: 0,
  sellerBefore: 0,
  otherTotal: 0
});

type totalsFootertype = {
  control?: Control<{ charges: AccountingCharge[] }> | undefined,
  currentLetter: string,
  hideTax: boolean
}

const TotalsFooter: FC<totalsFootertype> = ({ control, currentLetter, hideTax }) => {
  const results = useWatch({ control, name: 'charges' });
  const classes = useStyles();
  const {
    buyerAtclosing,
    buyerBefore,
    sellerAtclosing,
    sellerBefore,
    otherTotal
  } = calcResults(results);
  return (
    <TableRow className={classes.tableHeadRowSecond}>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell className={classes.tableHeaderPayee}>
        <TextField
          value="Totals"
          disabled
          color="secondary"
          variant="outlined"
          customClassName={classes.textField}
        />
      </TableCell>
      {(currentLetter === 'G' || currentLetter === 'F') && (
        <>
          <TableCell className={classes.tableHeaderNumber} />
          <TableCell className={classes.tableHeaderNumber} />
        </>
      )}
      {!hideTax && (
        <TableCell />
      )}
      <TableCell className={classes.tableHeaderNumber}>
        <NumberField
          disabled
          prefix="$"
          thousandSeparator
          color="secondary"
          className={classes.numberField}
          variant="outlined"
          value={buyerAtclosing}
        />
      </TableCell>
      <TableCell className={classes.tableHeaderNumber}>
        <NumberField
          disabled
          prefix="$"
          thousandSeparator
          color="secondary"
          className={classes.numberField}
          variant="outlined"
          value={buyerBefore}
        />
      </TableCell>
      <TableCell className={classes.tableHeaderNumber}>
        <NumberField
          disabled
          prefix="$"
          thousandSeparator
          color="secondary"
          className={classes.numberField}
          variant="outlined"
          value={sellerAtclosing}
        />
      </TableCell>
      <TableCell className={classes.tableHeaderNumber}>
        <NumberField
          disabled
          prefix="$"
          thousandSeparator
          color="secondary"
          className={classes.numberField}
          variant="outlined"
          value={sellerBefore}
        />
      </TableCell>
      <TableCell className={classes.tableHeaderNumber}>
        <NumberField
          disabled
          prefix="$"
          thousandSeparator
          color="secondary"
          className={classes.numberField}
          variant="outlined"
          value={otherTotal}
        />
      </TableCell>
    </TableRow>
  );
};

export default TotalsFooter;