import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { deleteCharges, updateCharges } from '../services/mutations';
import useAlert from 'utils/alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { formatAccountingData } from '../services';
import { AccountingCharge } from '../types';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchemaCharges } from '../services/validate-entry';
import { useForm } from 'react-hook-form';

type anchorElRowType = {
  anchorEl: string;
  accountingId: string;
  number: number;
};

const useTableCharges = (accountingData, currentLetter, updatedAccounting) => {
  const [formatedAccountingData, setFormatedAccountingData] = useState(
    formatAccountingData(accountingData) || []
  );
  const showAlert = useAlert();
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  const [anchorElRow, setAnchorElRow] = useState<anchorElRowType | null>(null);

  const [chargesOverrides, setChargesOverrides] = useState({});

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      charges: formatedAccountingData,
    },
    resolver: yupResolver(validationSchemaCharges),
    mode: 'onChange',
  });

  useEffect(() => {
    const currentFormValues = getValues().charges;
    const cantCharges = formatedAccountingData.length - 1;
    const currentCantCharges = currentFormValues.length - 1;
    let isNewEntry = false;

    if (
      currentFormValues.length > 0 &&
      currentFormValues[currentCantCharges].number === undefined
    ) {
      currentFormValues[currentCantCharges] = formatedAccountingData[cantCharges];
      isNewEntry = true;
    }

    reset({
      charges: isNewEntry ? currentFormValues : formatedAccountingData,
    });
  }, [formatedAccountingData]);

  const handleChangeMonth = (charge: AccountingCharge, idx: number) => {
    const perMonth = charge.per_month ?? 0;
    const months = charge.months ?? 0;
    const newCharge = {
      ...charge,
      tax: 0,
      seller_before: 0,
      seller_at: 0,
      buyer_before: 0,
      buyer_at: perMonth * months,
      by_others: 0,
    };
    const currentFormValues = getValues().charges;

    currentFormValues[idx] = newCharge;

    reset({
      charges: currentFormValues,
    });
  };

  const handleChangeAmount = (charge, idx, nameField) => {
    const amountCalculated = accountingData[idx]?.amount_calculated || 0;
    const amount =
      charge.buyer_at +
      charge.buyer_before +
      charge.by_others +
      charge.seller_at +
      charge.seller_before +
      charge.tax;

    if (amountCalculated !== 0 && amount !== amountCalculated) {
      setChargesOverrides({
        ...chargesOverrides,
        [`charge${idx}`]: [...(chargesOverrides[`charge${idx}`] || []), nameField],
      });
    }

    if (amountCalculated !== 0 && amount === amountCalculated) {
      const inputsChanged = chargesOverrides[`charge${idx}`] || [];
      setChargesOverrides({
        ...chargesOverrides,
        [`charge${idx}`]: inputsChanged.filter((input) => input !== nameField),
      });
    }
  };

  const handleCloseDialog = () => {
    setAnchorElRow(null);
  };

  const handleClickMenu = (event, accountingId, number) => {
    setAnchorElRow({
      anchorEl: event.currentTarget,
      accountingId,
      number,
    });
  };

  useEffect(() => {
    setFormatedAccountingData(
      formatAccountingData(accountingData).sort(
        (chargeA, chargeB) => chargeA.number - chargeB.number
      ) || []
    );
  }, [accountingData]);

  const openMenu = Boolean(anchorElRow);

  const [setUpdateCharges, updateChargesResponse] = useMutation(updateCharges, {
    onSuccess: (data) => {
      if (data) {
        showAlert(t('charges:success-updated'), 'success');
        const newData = data.helpOrderCharges.accounting || [];
        updatedAccounting(newData);
      }
    },
    onError: () => {
      showAlert(t('charges:error-updated'), 'error');
    },
  });

  const [setDeleteCharges, deleteChargesResponse] = useMutation(deleteCharges, {
    onSuccess: (data) => {
      if (data) {
        showAlert(t('charges:success-deleted'), 'success');
        setFormatedAccountingData(
          formatedAccountingData.filter(
            (charge) => charge.accounting_id !== anchorElRow?.accountingId
          )
        );
      }
    },
    onError: () => {
      showAlert(t('charges:error-deleted'), 'error');
    },
  });

  const resetIndexCharges = (charges: AccountingCharge[]) => {
    return charges.map((charge, idx) => ({ ...charge, number: idx + 1 }));
  };

  const handleDeleteCharge = (accountingId, number) => {
    setAnchorElRow(null);
    if (accountingId) {
      setDeleteCharges({
        id: orderId,
        accountingId,
      });
    } else {
      setFormatedAccountingData(
        resetIndexCharges(formatedAccountingData.filter((charge) => charge.number !== number))
      );
    }
  };

  const hanldeUpdateCharge = (dataCharges) => {
    setUpdateCharges({
      id: orderId,
      data: dataCharges,
      filters: {
        accounting: currentLetter,
      },
    });
  };

  const hanldeAddEmptyCharges = () => {
    const cantCharges = formatedAccountingData.length - 1;
    const emptyCharge: AccountingCharge = {
      accounting_id: null,
      description: '',
      kind: 'manual',
      number:
        (formatedAccountingData[cantCharges] !== undefined
          ? formatedAccountingData[cantCharges].number
          : 0) + 1,
      months: 0,
      per_month: 0,
      tax: 0,
      payee: '',
      payee_id: '',
      seller_before: 0,
      seller_at: 0,
      buyer_before: 0,
      buyer_at: 0,
      by_others: 0,
      letter: currentLetter,
    };

    setFormatedAccountingData(resetIndexCharges([...formatedAccountingData, emptyCharge]));
  };

  return {
    hanldeUpdateCharge,
    isLoading: updateChargesResponse.isLoading || deleteChargesResponse.isLoading,
    t,
    anchorElRow,
    handleClickMenu,
    handleCloseDialog,
    handleDeleteCharge,
    formatedAccountingData,
    hanldeAddEmptyCharges,
    control,
    getValues,
    handleSubmit,
    isValid,
    handleChangeAmount,
    chargesOverrides,
    handleChangeMonth,
  };
};

export default useTableCharges;
