import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: any) => ({
  placeholdertitle: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
    textAlign: 'center',
  },
  container: { height: '50vh' },
}));

const VoidPayoffs = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      alignContent="center"
      justifyContent="center"
      className={classes.container}
      direction="column"
    >
      <Grid item>
        <Typography variant="h6" className={classes.placeholdertitle}>
          {t('payoffs:not-payoffs')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" className={classes.placeholdertitle}>
          {t('payoffs:create-one-payoff')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default VoidPayoffs;
