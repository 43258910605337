import { Box, Switch, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useStyles } from '../../styles';
import { LoanFormType } from '../../types';
import { useTranslation } from 'react-i18next';

type Props = {
  isDataLoading: boolean;
  isUpdateLoading: boolean;
  loanForm: UseFormReturn<LoanFormType, object>;
};

export const LatePenaltyAs: FC<Props> = ({ isDataLoading, isUpdateLoading, loanForm }) => {
  const classes = useStyles();
  const { control } = loanForm;
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h6" className={classes.subTitle}>
        {t('loan:late-penalty-as')}
      </Typography>

      <Box>
        <Controller
          control={control}
          name="penalty_as_percent"
          render={({ field }) => (
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{t('accounting:amount')}</Typography>
              <Switch
                disabled={isDataLoading || isUpdateLoading}
                size="medium"
                checked={field.value}
                onBlur={field.onBlur}
                onChange={(event) => field.onChange(event)}
              />
              <Typography>{t('accounting:percent')}</Typography>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};
