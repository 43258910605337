import { Grid } from '@material-ui/core';
import NumberField from 'components/number-field';
import React, { FC, useEffect } from 'react';
import { Control, Controller, UseFormGetValues, UseFormSetValue, UseFormWatch, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formInfoOrder } from '../services';
import SectionTitle from './section-title';

type amountsPDFProps = {
  control: Control<formInfoOrder, object>,
  watch: UseFormWatch<formInfoOrder>,
  setValue: UseFormSetValue<formInfoOrder>
  getValues: UseFormGetValues<formInfoOrder>
}

const removeSpecialCharacters = (str: string) => str.replace(/[^0-9.]/g, '');

const AmountsPDFInformation: FC<amountsPDFProps> = ({ control, watch, setValue, getValues }) => {
  const { t } = useTranslation();
  const purchasePrice = useWatch({ control, name: 'purchase_price' });
  useEffect(() => {
    const listingCommissionPercent = getValues('listing_commission_percent');
    const sellingCommissionPercent = getValues('selling_commission_percent');
    if (!Number.isNaN(purchasePrice) && purchasePrice > 0) {
      const calculatedListing = Number(purchasePrice) * (listingCommissionPercent / 100);
      setValue('listing_commission', calculatedListing);
      const calculatedSelling = Number(purchasePrice) * (sellingCommissionPercent / 100);
      setValue('selling_commission', calculatedSelling);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchasePrice]);

  return (
    <>
      <SectionTitle title={t('orders:amounts')} />
      <Grid item style={{ marginBottom: 16 }}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="purchase_price"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <NumberField
                      {...field}
                      onChange={(e) => {
                        field.onChange(parseFloat(removeSpecialCharacters(e.target.value)));
                      }}
                      value={field.value}
                      label={t('orders:purchase-price')}
                      thousandSeparator=","
                      isNumericString
                      decimalScale={4}
                      prefix="$"
                      ref={(customRef) => {
                        ref({
                          focus: customRef.setFocus
                        });
                      }}
                      handleBlur={field.onBlur}
                      onFocus={() => { }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="loan_amount"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <NumberField
                      {...field}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(parseFloat(removeSpecialCharacters(e.target.value)));
                      }}
                      ref={(customRef) => {
                        ref({
                          focus: customRef.setFocus
                        });
                      }}
                      label={t('orders:loan-amount')}
                      thousandSeparator=","
                      decimalScale={4}
                      prefix="$"
                      handleBlur={field.onBlur}
                      onFocus={() => { }}
                    />
                  )}
                />
              </Grid>
            </Grid>

          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="earnest_money"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <NumberField
                      {...field}
                      value={field.value}
                      label={t('properties:earnest-money')}
                      thousandSeparator=","
                      decimalScale={4}
                      prefix="$"
                      onChange={(e) => {
                        field.onChange(parseFloat(removeSpecialCharacters(e.target.value)));
                      }}
                      ref={(customRef) => {
                        ref({
                          focus: customRef.setFocus
                        });
                      }}
                      handleBlur={field.onBlur}
                      onFocus={() => { }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="option_fee"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <NumberField
                      {...field}
                      value={field.value}
                      label={t('earnestAndCommissions:option-fee')}
                      thousandSeparator=","
                      decimalScale={4}
                      prefix="$"
                      onChange={(e) => {
                        field.onChange(parseFloat(removeSpecialCharacters(e.target.value)));
                      }}
                      ref={(customRef) => {
                        ref({
                          focus: customRef.setFocus
                        });
                      }}
                      handleBlur={field.onBlur}
                      onFocus={() => { }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="selling_commission_percent"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <NumberField
                      {...field}
                      value={field.value}
                      customRef={ref}
                      label={t('properties:selling-commission-percent')}
                      thousandSeparator=","
                      decimalScale={4}
                      prefix="%"
                      onValueChange={({ floatValue }) => {
                        field.onChange(floatValue);
                        if (floatValue && floatValue >= 0) {
                          setValue('selling_commission', purchasePrice * (floatValue / 100));
                        }
                      }}
                      handleBlur={field.onBlur}
                      onFocus={() => { }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="listing_commission_percent"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <NumberField
                      {...field}
                      value={field.value}
                      customRef={ref}
                      label={t('properties:listing-commission-percent')}
                      thousandSeparator=","
                      decimalScale={4}
                      prefix="%"
                      onValueChange={({ floatValue }) => {
                        field.onChange(floatValue);
                        if (floatValue && floatValue >= 0) {
                          setValue('listing_commission', purchasePrice * (floatValue / 100));
                        }
                      }}
                      onFocus={() => { }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="selling_commission"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <NumberField
                      {...field}
                      value={field.value}
                      label={t('properties:selling-commission')}
                      thousandSeparator=","
                      decimalScale={4}
                      prefix="$"
                      onChange={(e) => {
                        field.onChange(parseFloat(removeSpecialCharacters(e.target.value)));
                      }}
                      ref={(customRef) => {
                        ref({
                          focus: customRef.setFocus
                        });
                      }}
                      handleBlur={field.onBlur}
                      onFocus={() => { }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="listing_commission"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <NumberField
                      {...field}
                      value={field.value}
                      label={t('properties:listing-commission')}
                      thousandSeparator=","
                      decimalScale={4}
                      prefix="$"
                      onChange={(e) => {
                        field.onChange(parseFloat(removeSpecialCharacters(e.target.value)));
                      }}
                      ref={(customRef) => {
                        ref({
                          focus: customRef.setFocus
                        });
                      }}
                      onFocus={() => { }}
                      handleBlur={field.onBlur}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AmountsPDFInformation;
