import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { TableSkeleton } from 'components/table-skeleton';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LEDGER_CODE_LOAN_AMOUNT } from 'utils/constants';
import { Loan } from 'v2-types/order';

import { useStyles } from '../../styles';
import { ModalData } from '../../types';
import { orderAccountingByDescription } from '../../utils';
import { AccountingTable } from '../accounting-table';

type Props = {
  isDataLoading: boolean;
  selectedLoan?: Loan;
  setModalData: React.Dispatch<React.SetStateAction<ModalData | undefined>>;
};

export const RelatedAmountsAccounting: FC<Props> = ({
  isDataLoading,
  selectedLoan,
  setModalData,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const data = orderAccountingByDescription(
    selectedLoan?.accounting.filter((entry) => !entry.code.startsWith(LEDGER_CODE_LOAN_AMOUNT)) ||
      []
  );

  return (
    <Box>
      <Typography variant="h6" className={classes.subTitle}>
        {t('loan:related-amounts')}
      </Typography>
      {isDataLoading && (
        <TableSkeleton
          numberOfRows={5}
          showBorder={false}
          columns={{
            actions: {
              header: { title: t('accounting:actions'), width: '100px' },
              renderer: () => <Skeleton animation="wave" variant="text" height={40} />,
            },
            name: {
              header: { title: t('accounting:description'), align: 'left' },
              renderer: () => <Skeleton animation="wave" variant="text" height={40} />,
            },
            amount: {
              header: { title: t('accounting:amount'), align: 'left' },
              renderer: () => <Skeleton animation="wave" variant="text" height={40} />,
            },
          }}
        />
      )}
      {!isDataLoading && <AccountingTable data={data} onShowModal={setModalData} />}
    </Box>
  );
};
