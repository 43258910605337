import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import Header from '../components/header';
import { useTranslation } from 'react-i18next';
import TableCharges from '../components/tableCharges';
import useGetCharges from '../services/getCharges';
import SkeletonCharges from '../components/skeleton';
import ChargesContainer from '../components/chargeContainer';

const ChargesC = () => {
  const { t } = useTranslation();
  const currentLetter = 'C';

  const {
    accounting,
    updatedAccounting,
    isFetching,
    setHideTax,
    hideTax,
    redirectToPolicyInfo,
    discloSection,
    setChargesSection,
    policyIsLoading,
  } = useGetCharges(currentLetter);

  return (
    <ChargesContainer>
      <Grid container direction="column">
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <Paper>
            <Header
              setHideTax={setHideTax}
              hideTax={hideTax}
              title={t('charges:did-shop-for')}
              chargesIdentifier={currentLetter}
              t={t}
              redirectToPolicyInfo={redirectToPolicyInfo}
              discloSection={discloSection}
              setChargesSection={setChargesSection}
            />
            {isFetching ? (
              <SkeletonCharges />
            ) : (
              <TableCharges
                accountingData={accounting}
                currentLetter={currentLetter}
                updatedAccounting={updatedAccounting}
                hideTax={hideTax}
                policyIsLoading={policyIsLoading}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </ChargesContainer>
  );
};

export default ChargesC;
