import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { BaseButton } from 'components/BaseButton';
import NumberField from 'components/number-field';
import SelectField from 'components/select-field';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAddMortgage } from './add-mortgage-hooks';

const useStyles = makeStyles((theme: any) => ({
  bnutton: {
    textTransform: 'none',
    marginRight: theme.spacing(3),
  },
}));

type AddmortgageModalProps = {
  open: boolean;
  onClose: () => void;
};

const AddMortgageModal: FC<AddmortgageModalProps> = ({ open, onClose }) => {
  const classes = useStyles();
  const { methods, properties, createMortgageMutation, createMortgageReponse } = useAddMortgage(
    open,
    onClose
  );
  const { handleSubmit, control } = methods;
  const { t } = useTranslation();

  return (
    <Dialog
      open
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullWidth
      maxWidth="md"
    >
      <form
        onSubmit={handleSubmit((data) => {
          if (data.loan_number) {
            createMortgageMutation({
              id: data.id,
              estateId: data.estateId,
              loan_number: data.loan_number?.toString(),
            });
          }
        })}
      >
        <DialogContent style={{ padding: 24 }}>
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: 'black' }}>
                {t('payoffs:add-new-mortgage')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="loan_number"
                rules={{ required: true }}
                render={({ field }) => (
                  <NumberField
                    label={t('orders:loan-number')}
                    name="loan_number"
                    onChange={field.onChange}
                    handleBlur={field.onBlur}
                    value={field.value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="estateId"
                rules={{ required: true }}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    label={t('orders:property')}
                    dataKey="_id"
                    dataValue="_id"
                    dataText="address"
                    data={properties?.map(
                      (property) =>
                        ({
                          _id: property._id,
                          address: property.address.address,
                        } || [])
                    )}
                    handleChange={field.onChange}
                    handleBlur={field.onBlur}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flex="1" justifyContent="flex-end" flexDirection="row">
                <Button
                  className={classes.bnutton}
                  onClick={onClose}
                  disabled={createMortgageReponse.isLoading}
                >
                  {t('parties:cancel')}
                </Button>
                <BaseButton
                  text={t('common:save')}
                  type="submit"
                  isLoading={createMortgageReponse.isLoading}
                  disabled={!methods.formState.isValid}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default AddMortgageModal;
