import { AccountingLoanEntry, Loan } from 'v2-types/order';

export function formatPercent(value: number) {
  return `${parseFloat((value * 100).toFixed(2))}%`;
}

export function orderAccountingByDescription(accounting: AccountingLoanEntry[]) {
  return accounting.sort((entryA, entryB) => entryA.description.localeCompare(entryB.description));
}

export function getLoanFormDataFromLoan(loan: Loan) {
  const {
    _id,
    accounting,
    estate,
    interests,
    loan_number,
    term_months,
    term_years,
    ...rest } = loan;

  return rest;
}