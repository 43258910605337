import gqlRequest from "graphql/generic";
import { projectedPayments } from ".";

type deleteLedgerEntryParams = {
	id: string,
	data: Omit<projectedPayments, 'estimated_escrow' | 'mortgage_insurance'>
}

export type deleteLedgerEntryReturn = {
	deleteOrderLedgerEntry: {
	  _id:string
	}
  }

const queryDeleteEntry = `
    mutation UpdateOrderProjected($id: ID!, $data: OrderProjectedContent!) {
      updateOrderProjected(_id: $id, data: $data) {
        _id
      }
    }
  `;

export const updateOrderProjectedPayments = (params:deleteLedgerEntryParams) => gqlRequest<deleteLedgerEntryReturn, deleteLedgerEntryParams >(queryDeleteEntry, params);