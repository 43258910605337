import { useLLMProxyServiceContext } from 'contexts/llmProxySerivceContext';
import { useWorkspaceContext } from 'contexts/workspace-context';
import { TabOrder } from 'hooks/useOrderTabs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { setOrderConfiguration } from 'redux/order-configuration-reducer/actions';
import { setIdOrdenSelected } from 'redux/workspace-reducer/actions';
import querys from 'services/querys';
import { LEDGER_CODE_PURCHASE_PRICE } from 'utils/constants';

export default function useOrdersLayout() {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const [sidemenuOpen, setSidemenuOpen] = useState(true);

  const { id: orderId } = useParams<{ id: string }>();

  const { addOrderTab } = useWorkspaceContext();
  const { llmServiceRef } = useLLMProxyServiceContext();

  const getGraphOrder = useQuery(
    ['party-order', orderId],
    querys.getOrder,
    {
      enabled: !!orderId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          const {
            configuration,
            estates
          } = data.getOrder;

          if (configuration) {
            dispatch(setOrderConfiguration(configuration));
          }

          const orderTab: TabOrder = {
            id: data.getOrder._id,
            orderId: data.getOrder.order_id,
            firstPropertyAddress: ""
          };

          if (estates && estates.length > 0) {
            orderTab.firstPropertyAddress = estates[0].address.street_address;
            llmServiceRef.current.setViewInformation({
              id: "properties-info",
              text: `Order properties information: ${estates.map(estate => {
                const purchasePriceValue = estate.accounting.find((entry) => entry.code.includes(LEDGER_CODE_PURCHASE_PRICE));
                return `-${estate.address.address}: propertyId: ${estate._id}, purchase price information: { amount: ${purchasePriceValue?.amount ?? 0}, accountingId: ${purchasePriceValue?._id}\n`;
              })}`
            });
          }

          addOrderTab(orderTab);
          llmServiceRef.current.setOrderId(orderId);
          dispatch(setIdOrdenSelected(orderId));
        }
      }
    },
  );

  const orderType = getGraphOrder.data?.getOrder.configuration?.type || "";
  const properties = getGraphOrder.data?.getOrder.estates || [];
  const parties = getGraphOrder.data?.getOrder.parties || [];
  const isOrderLoading = getGraphOrder.isLoading;
  const orderHasAccountingData = Boolean(getGraphOrder.data?.getOrder.accounting);
  const idTitleCompany = getGraphOrder.data?.getOrder.parties.find((party) => party.kinds.includes("Title Company"))?._id ?? "";

  return {
    t,
    url,
    history,
    sidemenuOpen,
    setSidemenuOpen,
    properties,
    parties,
    isOrderLoading,
    statusFetchOrderData: getGraphOrder.status,
    orderID: getGraphOrder.data?.getOrder.order_id || "",
    orderHasAccountingData,
    orderType,
    idTitleCompany
  };
}
