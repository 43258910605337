import { Box } from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import React from 'react';
import { useTranslation } from 'react-i18next';

type OptionsProps = {
  onSave: () => void;
  isLoading: boolean;
};

export const Options = ({ onSave, isLoading }: OptionsProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <ContainedButton text={t('common:save')} icon="hdd" onClick={onSave} isLoading={isLoading} />
    </Box>
  );
};
