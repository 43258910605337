import SelectField from 'components/select-field';
import React from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';
import { OrderProperty } from 'types/order';

type PropertySelectFieldProps<T> = {
  control: Control<OrderProperty, object>,
  name: FieldPath<OrderProperty>,
  label: string,
  data: T[],
  dataKey: keyof T,
  dataValue: keyof T,
  dataText: keyof T,
  required?: boolean,
  isDisabled?: boolean,
  onSelectItem?: Function
}

export function PropertySelectField<T>({
  control,
  name,
  label,
  data,
  dataKey,
  dataValue,
  dataText,
  required = false,
  isDisabled = false,
  onSelectItem = () => { }
}: PropertySelectFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field, fieldState: { error } }) => (
        <SelectField
          label={label}
          value={data.length > 0 ? field.value?.toString()! : ''}
          handleBlur={field.onBlur}
          handleChange={(e) => {
            onSelectItem(e.target.value?.toString() ?? '');
            field.onChange(e);
          }}
          data={data as any[]}
          dataKey={dataKey}
          dataValue={dataValue}
          dataText={dataText}
          error={!!error}
          disabled={isDisabled}
        />
      )}
    />
  );
}
