import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import React from 'react';

type PayoffButtonProps = {
  selected: boolean;
  index: number;
  handleSelectPayoff: () => void;
  t: TFunction;
};

const useStyles = makeStyles((theme: any) => ({
  payoffButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    width: 120,
    marginRight: theme.spacing(1),
    '&:hover': { backgroundColor: theme.palette.input.border },
  },
  payoffInnerButton: { width: 120, textTransform: 'none' },
  payoffText: { color: theme.palette.primary.main },
  payoffCloseIcon: {
    fontSize: theme.spacing(2),
    width: 30,
    height: 30,
  },
  payoffSelectedButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': { backgroundColor: theme.palette.primary.main },
  },
  payoffSelectedText: { color: theme.palette.common.white },
}));

const PayoffButton = ({ selected, index, handleSelectPayoff, t }: PayoffButtonProps) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.payoffButton, { [classes.payoffSelectedButton]: selected })}>
      <Button
        className={classes.payoffInnerButton}
        onClick={(e) => {
          e.stopPropagation();
          handleSelectPayoff();
        }}
      >
        <Typography
          variant="body2"
          className={clsx(classes.payoffText, { [classes.payoffSelectedText]: selected })}
        >
          {`${t('payoffs:payoff')} #${index}`}
        </Typography>
      </Button>
    </Box>
  );
};

export default PayoffButton;
