import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert, TabContext, TabPanel } from '@material-ui/lab';
import { CustomTab } from 'components/common/custom-tab';
import { ContainedButton } from 'components/ContainedButton';
import { DropDown } from 'components/dropdown';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AddDataModal from './components/add-data-modal';
import CreateFileModal from './components/create-file-modal';
import { ManualDocumentUploadModal } from './components/manual-document-update-modal/manual-document-update-modal';
import MergeDocumentsModal from './components/merged-documents-modal';
import { RequestDocumentsModal } from './components/request-documents-modal/request-documents-modal';
import SignDocuments from './components/sign-documents/sign-documents';
import { DocumentViewer } from './components/table-documents/document-viewer';
import TableDocuments from './components/table-documents/table';
import { UpdateArchiveModal } from './components/table-documents/update-archive-modal';
import UpdateFleModal from './components/update-file-modal';
import useDocuments from './documents-hooks';
import useStyles from './styles';
import { ArchiveUpdate } from './types';

const DocumentsScene = () => {
  const {
    mainContainer,
    title,
    container,
    backdrop,
    filterActionsContainer,
    searchBox,
    searchIcon,
  } = useStyles();
  const { t } = useTranslation();
  const {
    documents,
    open,
    handleToggleModals,
    orderId,
    estates,
    parties,
    handleAddData,
    handleRemoveData,
    handleRemoveFile,
    handleupdateFile,
    handleCreateFile,
    handleRemoveArchive,
    handleRenameArchive,
    getTemplates,
    handleCheckboxChange,
    selectedFiles,
    openBackdrop,
    allFilesinDocuments,
    onSearch,
    htmlDocumentsContainerRef,
    searchWordClass,
    handleToggleSelectAllFiles,
    generateOrderFiles,
    handleUpdate,
    showAlerUpdate,
  } = useDocuments();

  const [currentTab, setcurrentTab] = useState('Documents');

  const handleChange = (event: any, index: string) => {
    setcurrentTab(index);
  };

  return (
    <Box className={container}>
      <Paper className={mainContainer}>
        <Box>
          <Grid container direction="row" wrap="nowrap" justifyContent="space-between">
            <Grid item>
              <Typography className={title}>{t('orders:documents')}</Typography>
            </Grid>
          </Grid>
        </Box>
        {showAlerUpdate && (
          <Box display="flex" flex="1">
            <Alert severity="info">
              <Typography style={{ fontWeight: 500, color: 'black' }} variant="body2">
                The documents are updating, wait a few minutes and refresh the documents
              </Typography>
            </Alert>
          </Box>
        )}
        <Box>
          <TabContext value={currentTab}>
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={currentTab}
              onChange={handleChange}
            >
              <CustomTab
                value="Documents"
                currentValue={currentTab}
                text={t('documents:documents')}
              />
              <CustomTab value="Signs" currentValue={currentTab} text={t('documents:signatures')} />
            </Tabs>
            <Box>
              <TabPanel value="Documents" style={{ padding: 0 }}>
                <Grid container direction="column">
                  <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="flex-start"
                    wrap="nowrap"
                    className={filterActionsContainer}
                  >
                    <Grid item container xs={6} spacing={1} alignItems="center" wrap="nowrap">
                      <Grid item xs={5}>
                        <TextField
                          hiddenLabel
                          placeholder={t('documents:search-documents')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <FontAwesomeIcon icon={faSearch} className={searchIcon} />
                              </InputAdornment>
                            ),
                          }}
                          variant="filled"
                          className={searchBox}
                          fullWidth
                          onChange={(e) => onSearch({ value: e.target.value, type: 'query' })}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <DropDown
                          options={parties}
                          optionValue="name"
                          optionText="name"
                          onChange={(value) => onSearch({ value, type: 'party' })}
                          defaultText={t('documents:select-party')}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <DropDown
                          options={estates}
                          optionValue="name"
                          optionText="name"
                          onChange={(value) => onSearch({ value, type: 'property' })}
                          defaultText={t('documents:select-property')}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container xs={6} justifyContent="flex-end" spacing={1}>
                      <Grid item>
                        <ContainedButton
                          text={t('documents:refresh-doc')}
                          onClick={() => {
                            handleUpdate();
                          }}
                          size="medium"
                        />
                      </Grid>
                      <Grid item>
                        <ContainedButton
                          text={t('documents:add-doc')}
                          onClick={() => handleToggleModals(orderId, 'createFile')}
                          size="medium"
                        />
                      </Grid>
                      <Grid item>
                        <ContainedButton
                          text={t('documents:upload-doc')}
                          onClick={() => handleToggleModals('', 'manualUploadDocument')}
                          size="medium"
                        />
                      </Grid>
                      <Grid item>
                        <ContainedButton
                          text={t('documents:request-doc')}
                          onClick={() => handleToggleModals('', 'requestDocument')}
                          size="medium"
                        />
                      </Grid>
                      <Grid item>
                        <ContainedButton
                          text={t('documents:regenrate-doc')}
                          onClick={() => generateOrderFiles({ id: orderId })}
                          size="medium"
                        />
                      </Grid>
                      {selectedFiles.length > 0 && (
                        <Grid item>
                          <ContainedButton
                            text={t('documents:merge-doc')}
                            onClick={() => handleToggleModals('', 'mergeDocuments')}
                            size="medium"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item ref={htmlDocumentsContainerRef}>
                    <TableDocuments
                      documents={documents}
                      handleToggleModal={handleToggleModals}
                      handleRemoveData={handleRemoveData}
                      handleRemoveFile={handleRemoveFile}
                      handleCheckboxChange={handleCheckboxChange}
                      selectedFiles={selectedFiles}
                      searchWordClass={searchWordClass}
                      handleToggleSelectAllFiles={handleToggleSelectAllFiles}
                      handleRemoveArchive={handleRemoveArchive}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="Signs" style={{ padding: 0 }}>
                <SignDocuments documents={documents} />
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </Paper>
      <DocumentViewer
        open={open.status && open.location === 'view'}
        handleClose={handleToggleModals}
        orderId={orderId}
        archive_id={open.id}
      />

      <AddDataModal
        open={open.status && open.location === 'addData'}
        handleClose={handleToggleModals}
        parties={parties}
        estates={estates}
        handleAddData={handleAddData}
        fileId={open.id}
      />

      {open.status && open.location === 'updateFile' && (
        <UpdateFleModal
          open={open.status && open.location === 'updateFile'}
          handleClose={handleToggleModals}
          fileId={open.id}
          data={open.data}
          handleupdateFile={handleupdateFile}
          allFilesinDocuments={allFilesinDocuments}
        />
      )}

      {open.status && open.location === 'updateArchive' && (
        <UpdateArchiveModal
          handleClose={handleToggleModals}
          handleUpdate={handleRenameArchive}
          data={open.data as ArchiveUpdate}
        />
      )}

      <CreateFileModal
        open={open.status && open.location === 'createFile'}
        getTemplates={getTemplates}
        handleClose={handleToggleModals}
        handleCreateFile={handleCreateFile}
      />

      {open.status && open.location === 'mergeDocuments' && (
        <MergeDocumentsModal
          handleClose={handleToggleModals}
          selectedFiles={selectedFiles}
          documents={documents}
          handleupdateFile={handleupdateFile}
        />
      )}

      <RequestDocumentsModal
        open={open.status && open.location === 'requestDocument'}
        parties={parties}
        documents={documents}
        handleClose={() => handleToggleModals('', '')}
      />
      <ManualDocumentUploadModal
        open={open.status && open.location === 'manualUploadDocument'}
        parties={parties}
        documents={documents}
        handleClose={() => handleToggleModals('', '')}
      />

      <Backdrop open={openBackdrop} className={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default DocumentsScene;
