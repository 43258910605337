import React from 'react';

import useProjectedPayments from './projected-payments-hook';
import ProjectedPaymentsScene from './projected-payments-scene';
import { projectedPayments } from './services';

const ProjectedPayments = () => {
  const {
    methods,
    updateProjectedPayments,
    updateOrderProjectedResponse,
    orderId
  } = useProjectedPayments();

  const handleSave = (values: projectedPayments) => {
    updateProjectedPayments({
      id: orderId,
      data: {
        principal_kind: values.principal_kind,
        interest_only: values.interest_only,
        prorations_period: values.prorations_period,
        includes_taxes: values.includes_taxes,
        includes_homeowners: values.includes_homeowners,
        includes_other: values.includes_other,
        other_description: values.other_description,
        escrow_homeowners: values.escrow_homeowners,
        escrow_taxes: values.escrow_taxes,
        escrow_other: values.escrow_other,
        year_range_1: Number(values.year_range_1),
        year_range_2: Number(values.year_range_2),
        principal_amount: Number(values.principal_amount),
        principal_min: Number(values.principal_min),
        principal_max: Number(values.principal_max),
        prorations_amount: Number(values.prorations_amount),
      }
    });
  };

  return (
    <ProjectedPaymentsScene
      methods={methods}
      loadingSave={updateOrderProjectedResponse.isLoading}
      handleSave={handleSave}
    />
  );
};

export default ProjectedPayments;
