/* eslint-disable jsx-a11y/anchor-is-valid */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getOrderConfiguration } from 'redux/order-configuration-reducer/selectors';
import { WorkspaceRoutes } from 'types/common';
import {
  NARRATIVE,
  QUESTIONS_AND_ANSWERS,
  ROUTE_DISBURSEMENTS_AND_RECEIPTS,
} from 'utils/constants';

import { getOrderCharges } from '../../services';
import Link from './link';

const useStyles = makeStyles((theme: any) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.2),
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  headerIcon: {
    color: theme.palette.primary.main,
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  container: { padding: theme.spacing(1) },
  subtitle: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2),
  },
  accordionContainer: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: 0,
    margin: 0,
  },
  accordionLabelExpandIcon: {
    color: theme.accent.main,
    fontSize: theme.spacing(1.5),
  },
  accordionLabel: { color: theme.accent.main },
  accordionLabelIcon: { marginRight: theme.spacing(1) },
  accordonDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  linkCharges: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chargesIcon: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.accent.main,
    color: theme.palette.common.black,
  },
  muiLink: {
    fontFamily: 'Roboto',
    color: theme.palette.common.white,
    opacity: 0.45,
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:hover': {
      color: theme.palette.common.white,
      opacity: 0.8,
    },
  },
}));

type OpenSidemenuProps = {
  t: TFunction;
  setSidemenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleViewChange: (view: WorkspaceRoutes, param?: string) => void;
};

const OpenSidemenu = ({ t, setSidemenuOpen, handleViewChange }: OpenSidemenuProps) => {
  const classes = useStyles();
  const configurationOrder = useSelector(getOrderConfiguration);
  const chargesMenuItems = useMemo(
    () => getOrderCharges(t, configurationOrder?.type || 'Closing Disclosure', handleViewChange),
    [handleViewChange, configurationOrder, t]
  );

  return (
    <>
      <Box className={classes.header}>
        <Typography>{t('orders:order-menu')}</Typography>

        <IconButton
          className={classes.headerIcon}
          onClick={(e) => {
            e.stopPropagation();
            setSidemenuOpen(false);
          }}
        >
          <FontAwesomeIcon icon="caret-square-left" size="xs" />
        </IconButton>
      </Box>

      <Box className={classes.container}>
        <>
          <Typography variant="overline" className={classes.subtitle}>
            {t('orders:order')}
          </Typography>

          <Accordion defaultExpanded className={classes.accordionContainer}>
            <AccordionSummary
              expandIcon={
                <FontAwesomeIcon icon="chevron-up" className={classes.accordionLabelExpandIcon} />
              }
            >
              <Typography variant="body2" className={classes.accordionLabel}>
                <FontAwesomeIcon icon="briefcase" className={classes.accordionLabelIcon} />
                {t('common:general')}
              </Typography>
            </AccordionSummary>

            <AccordionDetails className={classes.accordonDetailsContainer}>
              <Link view="overview" onClick={() => handleViewChange('overview')}>
                {t('orders:overview')}
              </Link>

              <Link view="dates-and-config" onClick={() => handleViewChange('dates-and-config')}>
                {t('orders:date-and-config')}
              </Link>

              <Link view="properties" onClick={() => handleViewChange('properties')}>
                {t('orders:properties')}
              </Link>

              <Link view="parties" onClick={() => handleViewChange('parties')}>
                {t('parties:parties')}
              </Link>

              <Link
                view="earnest-and-commissions"
                onClick={() => handleViewChange('earnest-and-commissions')}
              >
                {t('earnestAndCommissions:earnest-and-commissions')}
              </Link>

              <Link view="payoffs" onClick={() => handleViewChange('payoffs')}>
                {t('orders:payoffs')}
              </Link>

              <Link view="loans" onClick={() => handleViewChange('loans')}>
                {t('loan:loans')}
              </Link>

              <Link
                view="taxes-and-prorations"
                onClick={() => handleViewChange('taxes-and-prorations')}
              >
                {t('taxesAndProrations:taxes-and-prorations')}
              </Link>

              <Link
                view={QUESTIONS_AND_ANSWERS}
                onClick={() => handleViewChange(QUESTIONS_AND_ANSWERS)}
              >
                {t('orders:questions-and-answers')}
              </Link>

              <Link view={NARRATIVE} onClick={() => handleViewChange(NARRATIVE)}>
                {t('orders:narrative')}
              </Link>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordionContainer}>
            <AccordionSummary
              expandIcon={
                <FontAwesomeIcon icon="chevron-up" className={classes.accordionLabelExpandIcon} />
              }
            >
              <Typography variant="body2" className={classes.accordionLabel}>
                <FontAwesomeIcon icon="bookmark" className={classes.accordionLabelIcon} />
                {t('orders:titles')}
              </Typography>
            </AccordionSummary>

            <AccordionDetails className={classes.accordonDetailsContainer}>
              <Link
                view="policy-info-and-rates"
                onClick={() => handleViewChange('policy-info-and-rates')}
              >
                {t('policyInfoAndRates:policy-info-and-rates')}
              </Link>
              <Link view="documents" onClick={() => handleViewChange('documents')}>
                {t('orders:documents')}
              </Link>
            </AccordionDetails>
          </Accordion>
        </>
        <>
          <Typography variant="overline" className={classes.subtitle}>
            {t('common:closing')}
          </Typography>

          <Accordion className={classes.accordionContainer}>
            <AccordionSummary
              expandIcon={
                <FontAwesomeIcon icon="chevron-up" className={classes.accordionLabelExpandIcon} />
              }
            >
              <Typography variant="body2" className={classes.accordionLabel}>
                <FontAwesomeIcon
                  icon="file-invoice-dollar"
                  className={classes.accordionLabelIcon}
                />
                {t('charges:charges')}
              </Typography>
            </AccordionSummary>

            <AccordionDetails className={classes.accordonDetailsContainer}>
              {chargesMenuItems.map((menucharge) => (
                <Link
                  view={menucharge.view}
                  param={menucharge.param}
                  onClick={menucharge.onClick}
                  key={menucharge.param}
                >
                  <Box className={classes.linkCharges}>
                    {menucharge.title}
                    <Box className={classes.chargesIcon}>{menucharge.param.toUpperCase()}</Box>
                  </Box>
                </Link>
              ))}
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordionContainer}>
            <AccordionSummary
              expandIcon={
                <FontAwesomeIcon icon="chevron-up" className={classes.accordionLabelExpandIcon} />
              }
            >
              <Typography variant="body2" className={classes.accordionLabel}>
                <FontAwesomeIcon icon="share-alt" className={classes.accordionLabelIcon} />
                {t('disclosure:disclosure')}
              </Typography>
            </AccordionSummary>

            <AccordionDetails className={classes.accordonDetailsContainer}>

              <Link
                view="projected-payments"
                onClick={() => handleViewChange('projected-payments')}
              >
                {t('disclosure:projected-payments')}
              </Link>

              <Link
                view="cash-to-close"
                onClick={() => handleViewChange('cash-to-close')}
              >
                {t('disclosure:cash-to-close')}
              </Link>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordionContainer}>
            <AccordionSummary
              expandIcon={
                <FontAwesomeIcon icon="chevron-up" className={classes.accordionLabelExpandIcon} />
              }
            >
              <Typography variant="body2" className={classes.accordionLabel}>
                <FontAwesomeIcon
                  icon="money-bill-wave-alt"
                  className={classes.accordionLabelIcon}
                />
                {t('payments:payments')}
              </Typography>
            </AccordionSummary>

            <AccordionDetails className={classes.accordonDetailsContainer}>
              <Link
                view="payments"
                param="wire-transfers"
                onClick={() => handleViewChange('payments', 'wire-transfers')}
              >
                {t('payments:wire-tranfers')}
              </Link>

              <Link
                view={ROUTE_DISBURSEMENTS_AND_RECEIPTS}
                onClick={() => handleViewChange(ROUTE_DISBURSEMENTS_AND_RECEIPTS)}
              >
                {t('payments:disbursements-and-receipts')}
              </Link>

              <Link
                view="accounting"
                param="accounting"
                onClick={() => handleViewChange('payments', 'accounting')}
              >
                {t('accounting:accounting')}
              </Link>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordionContainer}>
            <AccordionSummary
              expandIcon={
                <FontAwesomeIcon icon="chevron-up" className={classes.accordionLabelExpandIcon} />
              }
            >
              <Typography variant="body2" className={classes.accordionLabel}>
                <FontAwesomeIcon icon="file-alt" className={classes.accordionLabelIcon} />
                {t('closing:closing')}
              </Typography>
            </AccordionSummary>

            <AccordionDetails className={classes.accordonDetailsContainer}>
              <Link
                view="closing"
                param="schedule-closing"
                onClick={() => handleViewChange('closing', 'schedule-closing')}
              >
                {t('closing:schedule-closing')}
              </Link>
            </AccordionDetails>
          </Accordion>
        </>
      </Box>
    </>
  );
};

export default OpenSidemenu;
