import { capitalize, Grid, makeStyles, Typography } from '@material-ui/core';
import NumberField from 'components/number-field';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingType } from 'v2-types/order';

const useStyles = makeStyles((theme: any) => ({
  totalDueContainer: {
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: theme.palette.secondary.background,
  },
  totalDueLabel: { color: theme.palette.primary.darker },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
  label: { color: theme.palette.tab.offselected },
}));

const TotalDue = ({ totalDue }: { totalDue: AccountingType }) => {
  const classes = useStyles();
  const totaldueDescription = totalDue.code.split('_');
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} className={classes.totalDueContainer}>
        <Grid item xs={2} className={classes.labelContainer}>
          <Typography variant="h6" className={classes.totalDueLabel}>
            {t(`payoffs:${totaldueDescription[0]}-${totaldueDescription[1]}`)}
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <NumberField
            disabled
            decimalScale={2}
            thousandSeparator
            variant="outlined"
            prefix="$"
            value={totalDue.amount}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TotalDue;
