/* eslint-disable react/no-array-index-key */
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { BaseButton } from 'components/BaseButton';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AGENCY_SELECTED_ID } from 'utils/constants';

import AmountsPDFInformation from './components/amounts-information-pdf';
import DatesPDFForm from './components/dates-form';
import OrderTypeForm from './components/ortder-type-form';
import PartiesForm from './components/parties-form';
import PropertyPDFInformation from './components/property-pdf-information';
import SkeletonForm from './components/skeleton-form';
import { formInfoOrder } from './services';
import { ListingEntry } from 'graphql/listings/queries';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: any) => ({
  container: { overflow: 'hidden', padding: theme.spacing(3) },
  title: { fontWeight: 500, color: theme.palette.common.black },
  buttonsContainer: { marginBottom: theme.spacing(1) },
  createButtonContainer: { marginLeft: theme.spacing(2) },
  buttonText: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
  },
  pdfContainer: {
    overflowY: 'scroll',
    padding: theme.spacing(2),
    maxHeight: '80vh'
  },
  centerElements: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  spacingSection: { marginBottom: theme.spacing(2) },
}));

type orderStepperSceneProps = {
  open: boolean,
  pdfInformation: {
    images: string[],
    blobFiles: Blob[]
  },
  onClose: () => void;
  methods: UseFormReturn<formInfoOrder, object>,
  states: {
    code: string;
    parent: string;
    name: string;
  }[],
  handleCreateOrderAI: (data: formInfoOrder) => void,
  loadingCreatingOrder: boolean,
  handleRetry: () => void,
  transactionTypes: ListingEntry[];
  types: ListingEntry[];
  workflows: ListingEntry[];
  selectTransactionType: (code: string) => void;
}

const OrderStepperScene: FC<orderStepperSceneProps> = ({
  open,
  pdfInformation,
  onClose,
  states,
  methods,
  handleCreateOrderAI,
  loadingCreatingOrder,
  handleRetry,
  transactionTypes,
  types,
  workflows,
  selectTransactionType
}) => {
  const classes = useStyles();

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    getValues,
    formState
  } = methods;

  const loadingform = watch('loadingForm');
  const errorform = watch('errorForm');
  const { t } = useTranslation();
  const agencySelected = localStorage.getItem(AGENCY_SELECTED_ID);
  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={onClose}>
      <DialogContent className={classes.container}>
        <form onSubmit={handleSubmit((data) => handleCreateOrderAI(data))}>
          <Grid container direction="row">
            <Grid item xs={6} className={classes.pdfContainer} style={{ backgroundColor: '#263238' }}>
              <Box flex="1" display="flex" />
              {pdfInformation?.images?.map((image, index) => (
                <Box className={classes.spacingSection}>
                  <img
                    src={image}
                    alt={`${index}${image.slice(0, 5)}`}
                    style={{ maxWidth: '100%' }}
                    key={`${index}-${image.slice(0, 10)}`}
                  />
                </Box>
              ))}
            </Grid>
            <Grid item xs={6} className={classes.pdfContainer}>

              <Grid container direction="column">
                <Grid item style={{ textAlign: 'center' }} className={classes.spacingSection}>
                  <Typography variant="h6" className={classes.title}>{t('orders:information-from-document')}</Typography>
                </Grid>
                <Grid item style={{ textAlign: 'center' }} className={classes.spacingSection}>
                  <Typography variant="body2" className={classes.title}>{t('orders:please-check-before')}</Typography>
                </Grid>

                {!agencySelected && (
                  <Grid item style={{ textAlign: 'center' }} className={classes.spacingSection}>
                    <Alert severity="error">
                      <Typography variant="body2" className={classes.title}>
                        {t('orders:please-select-order')}
                      </Typography>
                    </Alert>
                  </Grid>
                )}

                {errorform !== null && (
                  <Grid container direction="column" alignContent="center" spacing={2}>
                    <Grid item>
                      <Alert severity="error">
                        <Typography variant="body2">there was an error, please try again</Typography>
                      </Alert>
                    </Grid>
                    <Grid item className={classes.centerElements}>
                      <BaseButton
                        text="retry"
                        icon="arrow-alt-circle-right"
                        onClick={handleRetry}
                      />
                    </Grid>
                  </Grid>
                )}
                {loadingform ? <SkeletonForm /> : (
                  <Grid container direction="column" spacing={3}>
                    <Grid item xs={12}>
                      <PartiesForm
                        loadingParties={loadingform}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <OrderTypeForm
                        control={control}
                        transactionTypes={transactionTypes}
                        types={types}
                        workflows={workflows}
                        selectTransactionType={selectTransactionType}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PropertyPDFInformation
                        control={control}
                        states={states}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DatesPDFForm
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AmountsPDFInformation
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        getValues={getValues}
                      />
                    </Grid>
                    {!formState.isValid &&
                      <Grid item xs={12}>
                        <Grid item style={{ textAlign: 'center' }} className={classes.spacingSection}>
                          <Alert severity="error">
                            <Typography variant="body2" className={classes.title}>
                              Please fill in all fields and make sure the purchase price is greater than 0 before saving
                            </Typography>
                          </Alert>
                        </Grid>
                      </Grid>}
                    <Grid item className={classes.spacingSection}>
                      <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                        <Grid item>
                          <BaseButton
                            text="Cancel"
                            buttonVariant="outlined"
                            buttonColor="primary"
                            disabled={loadingCreatingOrder}
                            onClick={onClose}
                          />
                        </Grid>
                        <Grid item>
                          <BaseButton
                            text="Continue"
                            type="submit"
                            isLoading={loadingCreatingOrder}
                            disabled={!agencySelected || !formState.isValid}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>

            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default OrderStepperScene;
