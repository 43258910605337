import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import Header from '../components/header';
import { useTranslation } from 'react-i18next';
import TableCharges from '../components/tableCharges';
import useGetCharges from '../services/getCharges';
import SkeletonCharges from '../components/skeleton';
import ChargesContainer from '../components/chargeContainer';

const ChargesF = () => {
  const { t } = useTranslation();
  const currentLetter = 'F';

  const { accounting, updatedAccounting, isFetching, setHideTax, hideTax } =
    useGetCharges(currentLetter);

  return (
    <ChargesContainer>
      <Grid container direction="column">
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <Paper>
            <Header
              setHideTax={setHideTax}
              hideTax={hideTax}
              title={t('charges:prepaids')}
              chargesIdentifier={currentLetter}
              t={t}
            />
            {isFetching ? (
              <SkeletonCharges />
            ) : (
              <TableCharges
                accountingData={accounting}
                currentLetter={currentLetter}
                updatedAccounting={updatedAccounting}
                hideTax={hideTax}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </ChargesContainer>
  );
};

export default ChargesF;
